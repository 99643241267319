<template>

  <div>

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="3"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                v-model="limit"
                :options="limitOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
            <b-alert
                style="margin-top: 5px"
                variant="primary"
                show
            >
              <div class="alert-body">
                <span><strong>已勾选:{{
                    selected.length
                  }}  合计订货数量:{{ selected.reduce((sum, e) => sum + Number(e.item_count || 0), 0) }}</strong> </span>
              </div>
            </b-alert>
          </b-col>

          <!-- Search -->
          <b-col
              cols="12"
              md="9"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="advanced_search"
                  class="mr-1"
                  size="sm"
              >
                <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                />
                <span class="align-middle">高级搜索</span>
              </b-button>
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-success"
                  @click="deliveryNote(1)"
                  class="mr-1"
                  size="sm"
              >
                <feather-icon
                    icon="PrinterIcon"
                    class="mr-50"
                />
                <span class="align-middle">打印销售单</span>
              </b-button>
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-success"
                  @click="deliveryNote(2)"
                  class="mr-1"
              >
                <feather-icon
                    icon="PrinterIcon"
                    class="mr-50"
                />
                <span class="align-middle">销售打印(无价格)</span>
              </b-button>
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-success"
                  @click="distribution"
                  class="mr-1"
                  size="sm"
              >
                <feather-icon
                    icon="PrinterIcon"
                    class="mr-50"
                />
                <span class="align-middle">打印配货单</span>
              </b-button>
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="allChangeStatus('同意')"
                  class="mr-1"
                  size="sm"
                  v-if="psoUserId.includes(user.user_id+'')"
              >
<!--                <feather-icon-->
<!--                    icon="TruckIcon"-->
<!--                    class="mr-50"-->
<!--                />-->
                <span class="align-middle">批量同意</span>
              </b-button>
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="allChangeStatus('驳回')"
                  class="mr-1"
                  size="sm"
                  v-if="psoUserId.includes(user.user_id+'')"
              >
<!--                <feather-icon-->
<!--                    icon="TruckIcon"-->
<!--                    class="mr-50"-->
<!--                />-->
                <span class="align-middle">批量驳回</span>
              </b-button>
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-warning"
                  @click="allOutbound"
                  class="mr-1"
                  size="sm"
              >
                <feather-icon
                    icon="TruckIcon"
                    class="mr-50"
                />
                <span class="align-middle">批量出库</span>
              </b-button>
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="isReceiptMethod"
                  class="mr-1"
                  size="sm"
              >
                <span class="align-right">批量回执</span>
              </b-button>

              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="exportData"
                  class="mr-1"
                  size="sm"

              >
                <span class="align-middle">导出</span>
              </b-button>

              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="selectAllRows"
                  class="mr-1"
                  size="sm"
              >
                <span class="align-middle">全部勾选</span>
              </b-button>
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-danger"
                  @click="clearSelected"
                  size="sm"
              >
                <span class="align-middle">清空勾选</span>
              </b-button>
            </div>
          </b-col>
        </b-row>


        <!--查询条件-->
        <b-card
            v-show="advanced_search_modal"
        >
          <b-form
              @submit.prevent="searchByCondition"
              @reset.prevent="resetCondition"
          >
            <b-row>
              <!-- 查询条件     终端客户（点选）  团队名称（点选） 仓库名称（点选，默认当前人关联仓库）  -->
              <!--商品名称-->
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="商品名称:"
                    label-for="productName"
                    label-size="sm"

                >
                  <b-form-input
                      v-model="state.condition.productCondition"
                      name="productName"
                      class="d-inline-block mr-1"
                      size="sm"
                      placeholder="商品名称或69码"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="配货编号:"
                    label-for="mergeNo"
                    label-size="sm"

                >
                  <b-form-input
                      v-model="state.condition.mergeNo"
                      name="mergeNo"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>


              <!--      销售编号        -->
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="订货编号:"
                    label-for="order_no"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="state.condition.order_no"
                      name="order_no"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="是否出库:"
                    label-for="isCheckout"
                    label-size="sm"
                >
                  <v-select
                      id="isCheckout"
                      :options="getCodeOptions('yesno')"
                      v-model="state.condition.isCheckout"
                      name="isCheckout"
                      class="select-size-sm"
                      placeholder="是否出库"
                      :reduce="option => option.value"
                  />
                </b-form-group>
              </b-col>

              <!--      状态         -->
              <!--  <b-col
                        cols="12"
                        md="4"
                >
                  <b-form-group
                          label-cols="3"
                          label-cols-lg="3"
                          label="订单状态:"
                          label-for="orderStatus"
                          label-size="sm"
                  >
                    <v-select
                            id="status"
                            :options="getCodeOptions('pre_order_status')"
                            v-model="state.condition.status"
                            name="orderStatus"
                            class="select-size-sm"
                            placeholder="请选择订单状态"
                    />
                  </b-form-group>
                </b-col>-->

              <!--     售卖日期         -->
              <b-col md="4" cols="12">
                <xy-input-button label="售卖日期"
                                 type="time"
                                 v-on:change="fromChildren($event,['sales_time'])"
                                 :params="['sales_time']"
                                 :value="state.condition.sales_time"
                >
                </xy-input-button>
              </b-col>

              <!--团队-->
              <b-col
                  cols="12"
                  md="4"
              >
                <xy-input-button
                    label="团队名称"
                    type="input"
                    v-on:change="fromChildren($event,['storeName','storeid'])"
                    :params="['storeName','storeid']"
                    :value="state.condition.storeName"
                    modalName="团队"
                    placeholder="点击选择团队名称"
                >
                </xy-input-button>
              </b-col>

              <!--终端客户-->
              <b-col md="4" cols="12" class="mb-1"
              >
                <xy-input-button
                    label="终端客户"
                    type="input"
                    v-on:change="fromChildren($event,['channelName','channelId'])"
                    :params="['channelName','channelId']"
                    :value="state.condition.channelName"
                    modalName="终端客户"
                    placeholder="点击选择终端客户"
                >
                </xy-input-button>
              </b-col>

              <!-- 销售仓库 sales_warehouse -->
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="仓库"
                    label-for="user_level"
                    label-size="sm"
                    class="mb-1"
                >
                  <v-select
                      v-model="state.condition.warehouseArray"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      multiple
                      label="label"
                      :options="getCodeOptions('warehouse')"
                      class="select-size-sm"
                      :close-on-select="false"
                  />
                </b-form-group>
              </b-col>

              <!--     销售员         -->
              <b-col
                  cols="12"
                  md="4"
                  class="mb-2"
              >
                <xy-input-button
                    label="销售员"
                    type="input"
                    v-on:change="fromChildren($event,['sales_name','sales_id'])"
                    :params="['sales_name','sales_id']"
                    :value="state.condition.sales_name"
                    modalName="销售员"
                    selectMode="multi"
                    placeholder="点击选择销售员"
                >
                </xy-input-button>
              </b-col>

              <!--     创建人         -->
              <b-col
                  cols="12"
                  md="4"
                  class="mb-2"
              >
                <xy-input-button
                    label="创建人"
                    type="input"
                    v-on:change="fromChildren($event,['creatorName','creator'])"
                    :params="['creatorName','creator']"
                    :value="state.condition.creatorName"
                    modalName="创建人"
                    placeholder="点击选择创建人"
                >
                </xy-input-button>
              </b-col>

              <!--创建日期-->
              <b-col md="4" cols="12">
                <xy-input-button label="创建日期"
                                 type="time"
                                 v-on:change="fromChildren($event,['add_time'])"
                                 :params="['add_time']"
                                 :value="state.condition.add_time"
                >
                </xy-input-button>
              </b-col>

              <!--回执确认-->
              <b-col md="4" cols="12">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="回执确认"
                    label-for="is_receipt"
                    label-size="sm"
                    class="mb-1"
                >

                  <v-select
                      id="is_receipt"
                      :options="getCodeOptions('yesno')"
                      :clearable="true"
                      v-model="state.condition.is_receipt"
                      class="select-size-sm"
                      placeholder="请选择是否回执"
                      :reduce="option => option.value"
                  >
                  </v-select>
                </b-form-group>
              </b-col>

              <b-col md="4" cols="12">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="生成销售单"
                    label-for="is_create_order_id"
                    label-size="sm"
                    class="mb-1"
                >

                  <v-select
                      id="is_create_order_id"
                      :options="getCodeOptions('yesno')"
                      :clearable="true"
                      v-model="state.condition.isCreateSalesOrder"
                      class="select-size-sm"
                      placeholder="请选择是否生成销售单"
                      :reduce="option => option.value"
                  >
                  </v-select>
                </b-form-group>
              </b-col>

              <b-col md="4" cols="12">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="是否打印"
                    label-for="is_print"
                    label-size="sm"
                    class="mb-1"
                >

                  <v-select
                      id="is_print"
                      :options="getCodeOptions('yesno')"
                      :clearable="true"
                      v-model="state.condition.isPrint"
                      class="select-size-sm"
                      placeholder="请选择是否打印"
                      :reduce="option => option.value"
                  >
                  </v-select>
                </b-form-group>
              </b-col>



              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="bbmId:"
                    label-for="bbmId"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="state.condition.bbmId"
                      name="bbmId"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="bbmItemId:"
                    label-for="bbmItemId"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="state.condition.bbmItemId"
                      name="bbmItemId"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="销售权:"
                    label-for="status"
                    label-size="sm"
                >
                  <v-select
                      :options="getCodeOptions('purchase_team')"
                      v-model="state.condition.purchaseTeamId"
                      name="status"
                      class="select-size-sm"
                      placeholder="请选择销售权"
                  />
                </b-form-group>
              </b-col>

              <!--回执方式-->
              <b-col md="4" cols="12">
                <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="回执方式:"
                        label-for="receipt_type"
                        label-size="sm"
                        class="mb-1"
                >

                  <v-select
                          id="receipt_type"
                          :options="receiptTypeOptions"
                          :clearable="true"
                          v-model="state.condition.receipt_type"
                          class="select-size-sm"
                          placeholder="请选择回执方式"
                          :reduce="option => option.value"
                  >
                  </v-select>
                </b-form-group>
              </b-col>


              <b-col
                  cols="12"
                  md="12"
                  class="demo-inline-spacing d-flex align-items-center justify-content-end"
              >
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="submit"
                >
                  <span class="align-right">查询</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="reset"
                >
                  <span class="align-right">重置</span>
                </b-button>


                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="advanced_search"
                >
                  <span class="align-right">关闭</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>

      </div>
    </b-card>


    <b-card style="margin-top: 15px">
      <div>
        <div class="d-flex mb-2">
          <b-form-radio
              v-model="selectedT"
              value=""
              name="some-radios"
              plain
              class="vs-radio-con"

          >
            <span class="ml-50 mr-1">全部({{ dataTitle.total }})</span>
          </b-form-radio>
          <b-form-radio
              v-model="selectedT"
              value="0"
              name="some-radios"
              plain
              class="vs-radio-con"
          >
            <span class="ml-50 mr-1">草稿({{ dataTitle.preOrderStatus0 }})</span>
          </b-form-radio>
          <b-form-radio
              v-model="selectedT"
              name="some-radios"
              value="1"
              plain
              class="vs-radio-con"
          >
            <span class="ml-50 mr-1">待审核({{ dataTitle.preOrderStatus1 }})</span>
          </b-form-radio>
          <b-form-radio
              v-model="selectedT"
              name="some-radios"
              value="2"
              plain
              class="vs-radio-con"
          >
            <span class="ml-50 mr-1">已审核({{ dataTitle.preOrderStatus2 }})</span>
          </b-form-radio>
          <b-form-radio
              v-model="selectedT"
              name="some-radios"
              value="4"
              plain
              class="vs-radio-con"
          >
            <span class="ml-50 mr-1">已作废({{ dataTitle.preOrderStatus4 }})</span>
          </b-form-radio>
          <b-form-radio
              v-model="selectedT"
              name="some-radios"
              value="5"
              plain
              class="vs-radio-con"
          >
            <span class="ml-50 mr-1">已驳回({{ dataTitle.preOrderStatus5 }})</span>
          </b-form-radio>
          <b-form-radio
              v-model="selectedT"
              name="some-radios"
              value="6"
              plain
              class="vs-radio-con"
          >
            <span class="ml-50 mr-1">待出库({{ dataTitle.preOrderStatus6 }})</span>
          </b-form-radio>
          <b-form-radio
              v-model="selectedT"
              name="some-radios"
              value="7"
              plain
              class="vs-radio-con"
          >
            <span class="ml-50 mr-1">待回执({{ dataTitle.preOrderStatus7 }})</span>
          </b-form-radio>
          <b-form-radio
              v-model="selectedT"
              name="some-radios"
              value="8"
              plain
              class="vs-radio-con"
          >
            <span class="ml-50 mr-1">已完成({{ dataTitle.preOrderStatus8 }})</span>
          </b-form-radio>
        </div>
      </div>


      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          hover
          small
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
          selectable
          selected-variant="success"
          select-mode="multi"
          @row-selected="onRowSelected"
      >
        <template #row-details="row">
          <!--<b-card v-for="i in row.item.ext.items" :key="i.name">-->
          <b-card>
            <div>
              <b-table striped hover :items="row.item.ext.items" :fields="fields">
                <!-- :readonly="data.item.refuse_qty>0"-->

                <template #cell(line_id)="data">
                  {{ data.item.ext.lineId }}
                </template>

                <template #cell(sales_cost_type)="data">
                  <v-select
                      :options="getCodeOptions('sales_cost_type')"
                      v-model="data.item.ext.salesCostType"
                      class="select-size-sm"
                      @input="changeSalesCostType($event,data.item)"
                      :disabled="row.item.status!==0&&row.item.status!==5"
                  />
                </template>
                <template #cell(is_expenses)="data">
                  <b-form-checkbox
                      name="is_invoice"
                      switch
                      inline
                      v-model="data.item.is_expenses"
                      value="1"
                      unchecked-value="0"
                      @input="changeIsExpenses($event,data.item)"
                      :disabled="row.item.status!==0&&row.item.status!==5"
                  />
                </template>

                <template #cell(is_adjust_price)="data">
                  <b-form-checkbox
                          name="is_adjust_price"
                          switch
                          inline
                          v-model="data.item.is_adjust_price"
                          value="1"
                          unchecked-value="0"
                          @input="changeIsAdjustPrice($event,data.item)"
                          :disabled="!((isNumber(row.item.is_receipt)===0&&isNumber(row.item.is_checkout)===1)&&isNumber(data.item.is_expenses)===0)"
                  />
                </template>



                <template #cell(refuse_qty)="data"
                          v-if="row.item.status===2&&row.item.is_receipt!==1&&row.item.is_checkout===1">
                  <b-form-input
                      id="memo"
                      size="sm"
                      v-model="data.item.refuse_qty"
                      @blur="checkQty(data.item)"
                      type="number"
                      style="width:80px"
                  />
                </template>

                <template #cell(name)="data">
                  {{ data.item.name }}
                  <feather-icon
                      icon="CpuIcon"
                      size="21"
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="success"
                      @click="function () {
                      stockOccupied.warehouse_id=row.item.sales_warehouse
                      stockOccupied.product_id=data.item.product_id
                      $refs['modal-stock-lock'].show()
                      $forceUpdate()
                      }"
                  />
                </template>

                <template #cell(location_id)="data">
                  {{ data.item.ext.locationname }}
                </template>

                <template #cell(stock_year)="data">
                  {{ data.item.stock_year }}-{{data.item.stock_month}}
                </template>

                <template #cell(product_label)="data">
                  {{ data.item.ext.productLabel }}
                </template>

                <template #cell(purchaseTeam)="data">
                  {{ getCodeLabel('purchase_team', row.item.ext.purchaseTeam) }}
                </template>

                <template #cell(cost)="data">
                  {{ data.item.ext.avgCost }}
                </template>

                <!--销售数量 待审核状态可以修改-->
                <template #cell(sales_qty)="data" v-if="row.item.status===1">
                  <b-form-input
                      id="sales_qty"
                      size="sm"
                      v-model="data.item.sales_qty"
                      @blur="checkSalesQty(data.item)"
                      type="number"
                      style="width:80px"
                      :readonly="data.item.is_expenses==1"
                  />
                </template>

                <template #cell(box_quantity)="data">
                  <b-form-input
                      id="box_quantity"
                      size="sm"
                      v-model="data.item.box_quantity"
                      @blur="checkBoxQuantity(data.item)"
                      type="number"
                      style="width:80px"
                  />
                </template>

                <template #cell(box_quantity_qty)="data">
                  {{
                    isNumber(data.item.box_quantity) == 0 ? '' : Math.ceil(data.item.sales_qty / data.item.box_quantity)
                  }}
                  <!--<b-form-input
                          id="box_quantity_qty"
                          size="sm"
                          v-model="data.item.ext.box_quantity"
                          @blur="checkBoxQuantity(data.item)"
                          type="number"
                          style="width:80px"
                  />-->
                </template>


                <!--单价 待审核状态可以修改-->
                <template #cell(sales_price)="data" v-if="row.item.status===1">
                  <b-form-input
                      id="sales_price"
                      size="sm"
                      v-model="data.item.sales_price"
                      @blur="checkSalesPrice(data.item)"
                      type="number"
                      style="width:80px"
                      :readonly="data.item.is_expenses==1"
                  />
                </template>


                <template #cell(actions)="data" v-if="row.item.status===1">
                  <b-dropdown
                      variant="link"
                      no-caret
                  >
                    <template #button-content>
                      <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          class="align-middle text-body"
                      />
                    </template>
                    <!--操作按钮-->
                    <b-dropdown-item @click="deleteItem(data)">
                      <feather-icon icon="DeleteIcon"/>
                      <span class="align-middle ml-50">删除</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </template>


                <template #cell(grossProfit)="data">
                  <span>{{ data.item.ext.grossProfit }}</span>
                </template>

                <template #cell(grossProfitMargin)="data">
                  <span
                      :class="data.item.ext.grossProfitMargin<=0?'text-danger':''">{{
                      data.item.ext.grossProfitMargin
                    }}%</span>
                </template>

              </b-table>
            </div>
          </b-card>
        </template>
        <!-- Columns -->
        <template #cell(id)="data">
          <b-link
              :to="{ name: 'apps-presalesorder-edit', query: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
          >
            #{{ data.item.id }}
          </b-link>
        </template>

        <!-- Column: Type -->
        <template #cell(grossProfit)="data">
          <span>{{ data.item.ext.grossProfit }}</span>
        </template>

        <template #cell(cost_subtotal)="data">
          <span>{{ data.item.ext.preCostTotal }}</span>
        </template>

        <template #cell(cost_subtotal)="data">
          <span>{{ data.item.ext.preCostTotal }}</span>
        </template>

        <template #cell(grossProfitMargin)="data">
          <span
              :class="data.item.ext.grossProfitMargin<=0?'text-danger':''">{{ data.item.ext.grossProfitMargin }}%</span>
        </template>

        <template #cell(sales_warehouse)="data">
          {{ data.item.ext['warehouseName'] }}
        </template>

        <template #cell(add_time)="data">
          {{ toDate(data.item.add_time) }} {{ getCodeLabel('user', data.item.creator) }}
        </template>

        <template #cell(delivery_time)="data">
          {{ toDate(data.item.delivery_time) }}
        </template>


        <template #cell(sales_time)="data">
          {{ toDate(data.item.sales_time) }}
        </template>


        <template #cell(modify_time)="data">
          <div v-if="!isEmpty(data.item.modify_time)">
            {{ toDate(data.item.modify_time) }} {{ getCodeLabel('user', data.item.updator) }}
          </div>
        </template>

        <template #cell(reviewer)="data">
          {{ isEmpty(data.item.reviewed_time)?'':toDate(data.item.reviewed_time) }} {{ getCodeLabel('user', data.item.reviewer) }}
        </template>

        <template #cell(is_checkout)="data">
          <feather-icon
              icon="XIcon"
              size="21"
              v-if="data.item.is_checkout==0||data.item.is_checkout==undefined"
          />
          <feather-icon
              icon="CheckIcon"
              size="21"
              v-else
          />
        </template>

        <template #cell(is_receipt)="data">
          <feather-icon
              icon="XIcon"
              size="21"
              v-if="data.item.is_receipt==0||data.item.is_receipt==undefined"
          />
          <feather-icon
              icon="CheckIcon"
              size="21"
              v-else
          />
        </template>

        <template #cell(create_order_id)="data">
          <div v-if="data.item.create_order_id==0||data.item.create_order_id==undefined">
            <feather-icon
                icon="XIcon"
                size="21"
            />
          </div>

          <div v-if="data.item.create_order_id>0">
            <feather-icon
                icon="CheckIcon"
                size="21"
                @click="toURL(data.item.create_order_id)"
            />
          </div>

        </template>

        <!-- <template #cell(is_receipt)="data">
           {{ getCodeLabel('yesno', data.item.is_receipt===1?1:0) }}
         </template>-->


        <template #cell(memo)="data">
          <div :id="`remark-row-${data.item.id}`">
            {{ data.value.substring(0, 3) }}
            <span v-if="data.value.length > 3"> ...</span>
          </div>
          <b-tooltip
              :target="`remark-row-${data.item.id}`"
              placement="top"
              v-if="data.value"
          >
            {{ data.value }}
          </b-tooltip>
        </template>

        <template #cell(clerk_memo)="data">
          <div :id="`clerk_memo-row-${data.item.id}`">
            {{ data.value.substring(0, 3) }}
            <span v-if="data.value.length > 3"> ...</span>
            <feather-icon icon="EditIcon" @click="showClerkMemo(data.item)"/>
          </div>
          <b-tooltip
              :target="`clerk_memo-row-${data.item.id}`"
              placement="top"
              v-if="data.value"
          >
            {{ data.value }}
          </b-tooltip>
        </template>

        <!--订单类型 1线下 2线上-->
        <template #cell(type_id)="data">
          {{ data.item.type_id === 1 ? '线下' : '线上' }}
        </template>

        <template #cell(status)="data">
          {{ getCodeLabel('pre_order_status', data.item.status) }}
        </template>


        <template #cell(receipt_attachments)="data">
          <div>
            <feather-icon icon="EditIcon" @click="showReceiptAttachments(data.item)"/>
            <attachment-upload :theme="'preview-dropdown'"
                               :attachment_id="'receipt_attachments'"
                               :id="data.item.receipt_attachments"
                               :object_type="'receipt_attachments'"
                               :object_id="0"
                               :readonly="true"
                               @change=""

            />
          </div>
        </template>

        <template #cell(order_no)="data">
          <b-form-checkbox v-model="data.detailsShowing"
                           name="check-button"
                           @change="data.toggleDetails"
                           button-variant="default"
                           size="sm"
                           button>
            <feather-icon
                icon="MinusSquareIcon"
                size="15"
                class="align-middle text-body"
                v-show="data.detailsShowing==true"
            />
            <feather-icon
                icon="PlusSquareIcon"
                size="16"
                class="align-middle text-body"
                v-show="data.detailsShowing==false"
            />
          </b-form-checkbox>
          {{ data.item.bbm_ful_fillment_order_id }}
          <br>
          [#{{ data.item.id }}]{{ data.item.order_no }}
        </template>

        <template #cell(price_check)="data">
          <feather-icon
              icon="XIcon"
              size="21"
              v-if="data.item.price_check_status==undefined"
          />
          <b-link
              :to="{name: 'apps-pricecheck-list', query: { order_no: data.item.order_no}}"
              target="_blank"
          >
            <feather-icon
                icon="CheckIcon"
                size="21"
                v-if="data.item.price_check_status!=undefined"
            />
          </b-link>
        </template>

        <template #cell(price_check_status)="data">
          <b-badge
              pill
              :variant="`light-${getCodeColor('price_check_status', data.item.price_check_status)}`"
          >
            {{ getCodeLabel("price_check_status", data.item.price_check_status) }}
          </b-badge>
        </template>


        <!-- Column: State -->
        <template #cell(state)="data">
          <b-link
              @click="changeState(data.item)"
          >
            <b-badge
                pill
                :variant="`light-$ {getCodeColor('state', data.item.state)}`"

            >
              {{ getCodeLabel('state', data.item.state) }}
            </b-badge>
          </b-link>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
              variant="link"
              no-caret
          >

            <template #button-content>
              <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
              />
            </template>
            <!--操作按钮-->
            <div>

                  <span v-if="(data.item.status === 0 || data.item.status === 5) && data.item.price_check_status!==1">
                         <b-dropdown-item
                             :to="{ name: 'apps-presalesorder-edit', query: { id: data.item.id },params:{storeId: data.item.store_id} }"
                         >
                  <feather-icon icon="EditIcon"/>
                  <span class="align-middle ml-50">编辑</span>
                </b-dropdown-item>
                   </span>
              <span v-if="data.item.status === 0 || data.item.status === 5">
                         <b-dropdown-item @click="changeStatus(data.item,1,'提交审核')">
                            <feather-icon icon="FileTextIcon"/>
                            <span class="align-middle ml-50">提交</span>
                         </b-dropdown-item>
                   </span>

              <span v-if="data.item.status === 0 || data.item.status === 5">
                         <b-dropdown-item @click="changeStatus(data.item,4,'作废')">
                            <feather-icon icon="FileTextIcon"/>
                            <span class="align-middle ml-50">作废</span>
                         </b-dropdown-item>
                   </span>

              <span v-if="data.item.status === 1&&psoUserId.includes(user.user_id+'')">
                         <b-dropdown-item @click="changeStatus(data.item,'','同意')">
                            <feather-icon icon="FileTextIcon"/>
                            <span class="align-middle ml-50">同意</span>
                         </b-dropdown-item>

                       <b-dropdown-item @click="changeStatus(data.item,'5','驳回')">
                            <feather-icon icon="FileTextIcon"/>
                            <span class="align-middle ml-50">驳回</span>
                         </b-dropdown-item>

              </span>

              <span v-if="data.item.status === 2 ">
                         <b-dropdown-item @click="changeStatus(data.item,5,'撤销审核')">
                            <feather-icon icon="FileTextIcon"/>
                            <span class="align-middle ml-50">撤销审核</span>
                         </b-dropdown-item>
                   </span>

              <span v-if="data.item.is_checkout === 1">
                  <b-dropdown-item @click="showChargeModal(data.item,1)">
                    <feather-icon icon="ToolIcon"/>
                    <span class="align-middle ml-50">生成装卸费</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="showChargeModal(data.item,2)">
                    <feather-icon icon="ToolIcon"/>
                    <span class="align-middle ml-50">生成物流费</span>
                  </b-dropdown-item>
              </span>
            </div>


          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>

        <b-modal
            id="modal-single"
            ok-only
            ok-title="确认"
            @ok="onSelectSingle"
            cancel-title="取消"
            centered
            size="lg"
            :title="'请选择'+modalName"
            ref="singleModal"
        >

          <company-select
              ref="companySelect" :companyType="companyType" v-if="modalName === '销售主体' || modalName ==='终端客户' "
          >
          </company-select>
          <!-- :userDepartment="userDepartment"  判断负责人所属部门-->
          <user-select
              ref="userSelect" :userDepartment="userDepartment" v-else-if="modalName === '销售员'||modalName==='创建人' "
          >
          </user-select>

          <store-list
              ref="storeList" v-else-if="modalName === '团队' "
          >
          </store-list>


        </b-modal>

        <!-- 抬头弹出窗口 -->
        <b-modal
            id="warehouseHeadModal"
            ok-only
            ok-title="确认"
            cancel-title="取消"
            centered
            size="sm"
            title="选择抬头"
            ref="warehouseHeadModal"
            @ok="deliveryNote(selectIndex)"
            @close="cleanWarehouseHeadArray"
            no-close-on-backdrop
            no-close-on-esc
        >
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="抬头"
              label-for="label"
              label-size="sm"
              class="mb-1"
          >
            <v-select
                :options="warehouseHeadArray"
                :clearable="true"
                v-model="warehouseHead"
                @input="setWarehouseHead($event)"
                class="select-size-sm"
                placeholder="请选择抬头"
            />
          </b-form-group>
        </b-modal>

        <!-- 加载modal -->
        <b-modal
            id="loadingModal"
            centered
            hide-header
            no-close-on-backdrop
            no-close-on-esc
            hide-footer
            ref="loadingModal"
        >
          <p class="my-4">单据创建中，请勿关闭浏览器!</p>
        </b-modal>

        <!--附件   hide-footer="true"-->
        <b-modal
            id="modal-attachments-receipt"
            centered
            ok-only
            ok-title="确认"
            size="lg"
            title="添加附件"
            ref="remarkModal"
            @ok="saveAttachments"
            @hidden="clearInput"
        >


          <b-row>

            <b-col cols="12">
              <b-form-group
                  label-cols="1"
                  label-cols-lg="1"
                  label="附件"
                  label-for="attachments_ids"
                  label-size="sm"
                  class="mb-1"
              >
                <attachment-upload :theme="'files'"
                                   :attachment_id="'attachments_ch'"
                                   :id="attachmentsStr"
                                   :object_type="'allot_outbound'"
                                   :object_id="attachmentsObjectIdCh"
                                   @change="onUploadedExcel"
                />
              </b-form-group>
            </b-col>


          </b-row>

        </b-modal>

        <b-modal
            id="modal-remark"
            ok-only
            ok-title="确认"
            @ok="saveRemark"
            cancel-title="取消"
            centered
            size="lg"
            title="文员批注"
            ref="clerkMemoModal"
        >
          <b-card :header="`编号：${selectedItem.order_no}`">
            <b-form-textarea
                id="textarea"
                v-model="remarkInfo"
                rows="3"
                max-rows="6"
            />
          </b-card>
        </b-modal>

        <!-- 来自bb订单驳回原因 -->
        <b-modal
            id="bbRejectModal"
            ok-only
            ok-title="确认"
            cancel-title="取消"
            centered
            size="sm"
            title="选择原因"
            ref="bbRejectModal"
            @ok="bbConfirm"
            no-close-on-backdrop
            no-close-on-esc
        >
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="原因"
              label-for="label"
              label-size="sm"
              class="mb-1 required"
          >
            <v-select
                :options="getCodeOptions('bbm_refuse_reason')"
                :clearable="true"
                @input="setBbmRefuseReason($event)"
                class="select-size-sm"
                placeholder="请选择原因"
            />
          </b-form-group>
        </b-modal>


      </div>
    </b-card>
    <b-modal
        id="companyModal"
        size="lg"
        title="添加搬运信息"
        ref="companyModal"
        hide-footer
    >
      <div>
        <b-col md="12">
          <b-row>
            <b-col md="6">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="司机车牌"
                  label-for="car_no"
                  label-size="sm"
                  class="mb-1"
              >
                <b-form-input
                    id="car_no"
                    size="sm"
                    v-model="car_no"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="车型"
                  label-for="car_detail"
                  label-size="sm"
                  class="mb-1"
              >
                <v-select
                    id="car_detail"
                    :options="getCodeOptions('car_detail').slice(0,11)"
                    class="select-size-sm"
                    v-model="car_detail"
                    :reduce="option => option.value"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col md="12">
          <b-row>
            <b-col md="6">
              <modal-select
                  label="装卸队"
                  type="input"
                  v-on:change="getLoad($event)"
                  :params="['company_name_load','company_id_load']"
                  :value="company_name_load"
                  modalName="装卸队"
                  :warehouseId="warehouseIdModel"
                  placeholder="点击选择装卸队"
              >
              </modal-select>
            </b-col>
            <b-col md="6">
              <modal-select
                  label="承运商"
                  type="input"
                  v-on:change="getTransport($event)"
                  :params="['company_name_transport','company_id_transport']"
                  :value="company_name_transport"
                  modalName="承运商"
                  :show-all-logistics="false"
                  :warehouse-id-from="warehouseIdModel"
                  placeholder="点击选择承运商"
              >
              </modal-select>
            </b-col>
          </b-row>
        </b-col>

        <b-col
            cols="12"
            class="mt-50"
        >
          <b-button
              variant="primary"
              class="mr-1"
              @click="submitInfo"
          >
            保存
          </b-button>
        </b-col>
      </div>

    </b-modal>

    <b-modal
        id="modal-stock-lock"
        ref="modal-stock-lock"
        no-close-on-backdrop
        cancel-title="取消"
        centered
        size="xl"
        title="锁库单据"
    >
      <stock-occupied-list
      :warehouse_id="stockOccupied.warehouse_id"
      :product_id="stockOccupied.product_id"
      >

      </stock-occupied-list>
    </b-modal>

    <fee-charge-select ref="feeRef" :object-type=3 :object-id=preSalesOrderId :warehouse-id=warehouseIdModel :warehouse-id-from=warehouseIdModel></fee-charge-select>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BFormRadio,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted, reactive, onMounted, toRefs} from '@vue/composition-api'
import {
  avatarText,
  toTime,
  toDate,
  getCodeOptions,
  getCode,
  getCodeLabel,
  getCodeColor,
  isNumber,
  isEmpty, lodopWatermark,
} from '@core/utils/filter'
import presalesorderUseList from './presalesorderUseList'
import presalesorderStore from './presalesorderStore'
import presalesorderitemStore from '@/views/apps/presalesorderitem/presalesorderitemStore'
import Ripple from 'vue-ripple-directive'
import AttachmentUpload from '@/views/apps/attachment/AttachmentUpload'
import CompanySelect from '../company/CompanySelect'
import UserSelect from '@/views/apps/user/user-modal/UserSelect'
import StoreList from '@/views/apps/store/store-modal/StoreListSelect'
import flatPickr from 'vue-flatpickr-component'
import XyInputButton from '@/views/components/xy/XyInputButton'
import {useToast} from 'vue-toastification/composition'
import {useRouter} from '@core/utils/utils'
import storeStore from '@/views/apps/store/storeStore'
import salesorderStore from '@/views/apps/offlinesalesorder/salesorderStore'
import operationlimitStore from '@/views/apps/operationlimit/operationlimitStore'
import companyStore from '@/views/apps/company/companyStore'
import {getUserData} from '@/auth/utils'
import storesalesStore from '@/views/apps/storesales/storesalesStore'
import {getLodop} from '@/libs/LodopFuncs'
import lodopStore from '@/views/apps/lodop/lodopStore'
import ModalSelect from "@/views/components/modal/ModalSelect";
import axios from '@axios'
import StockOccupiedList from "@/views/apps/stockoccupied/StockOccupiedList.vue";
import FeeChargeSelect from "@/views/apps/feeloadsetting/FeeChargeSelect";

export default {
  components: {
    StockOccupiedList,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    XyInputButton,
    CompanySelect,
    UserSelect,
    StoreList,
    AttachmentUpload,
    flatPickr,
    BFormRadio,
    ModalSelect,
    FeeChargeSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      stockOccupied:{},
      advanced_search_modal: false,
      modalName: '',
      channelName: '',
      salesName: '',
      companyName: '',
      storeName: '',
      channelArray: [],
      colorSelect: 'primary',
      fields: [
        {
          key: 'line_id',
          label: '序号',
        },
        {
          key: 'bbm_ful_fillment_order_line_id',
          label: 'BBItem',
        },
        {
          key: 'code',
          label: '69码',
        },
        {
          key: 'name',
          label: '商品名称',
        },
        {
          key: 'stock_year',
          label: '生产日期',
        },
        {
          key: 'purchaseTeam',
          label: '销售权',
        },
        {
          key: 'specification',
          label: '商品规格',
        },
        {
          key: 'product_label',
          label: '商品标签',
        },
        {
          key: 'sales_qty',
          label: '销售数量',
        },
        {
          key: 'refuse_qty',
          label: '拒收数量',
        },
        {
          key: 'is_adjust_price',
          label: '是调价',
        },
        {
          key: 'sales_price',
          label: '单价',
        },
        {
          key: 'is_expenses',
          label: '是费用',
        },
        {
          key: 'sales_cost_type',
          label: '费用科目',
        },
        {
          key: 'subtotal',
          label: '销售金额',
        },
        {
          key: 'cost',
          label: '平均成本',
        },
        {
          key: 'grossProfit',
          label: '毛利',
        },
        {
          key: 'grossProfitMargin',
          label: '毛利率',
        },
        {
          key: 'location_id',
          label: '库位',
        },
        {
          key: 'box_quantity',
          label: '箱规',
        },
        {
          key: 'box_quantity_qty',
          label: '箱数',
        },
        {
          key: 'memo',
          label: '备注',
        },
        {
          key: 'actions',
          label: '操作',
        },
      ],
      warehouseHeadArray: [],
      warehouseHead: '',
      selected: [],
      attachmentsStr: '',
      orderId: '',
      attachmentsId: '',
      attachmentsObjectIdCh: '',
      count: 0,
      remarkInfo: '',
      selectedItem: [],
      selectIndex: '',
      bbmRefuseReason: 0,
      bbmRefuseReasonLabel: '',
      reasonType: 0,
      preSalesOrderId: 0,
      useCompany: false,
      company_name_load: '',
      company_id_load: '',
      company_name_transport: '',
      company_id_transport: '',
      warehouseIdModel: 0,
      car_no: '',
      car_detail: '',
      receiptTypeOptions:[
        {
          label: '手动回执',
          value:1,
          position:0,
          state:1,
        },
        {
          label: '自动回执',
          value:2,
          position:1,
          state:1,
        }
      ],
      psoUserId:[],
      user:{},
    }
  },
  methods: {
    changeState: function (data) {
      const state = 1 - data.state
      store.dispatch('presalesorder/state', {
        id: data.id,
        state: state,
      }).then(res => {
        data.state = state
      })
    },
    clearInput: function () {
      this.attachmentsStr = ''
      this.attachmentsObjectIdCh = ''
      this.$refs['refListTable'].refresh()
    },
    showSingleModal(modalName) {
      if (modalName === '终端客户') {
        this.companyType = 'OffSalesChannelCustomer'
      }
      this.modalName = modalName
      this.$refs['singleModal'].show()
    },
    onSelectSingle() {
      let data = {}
      switch (this.modalName) {
        case '销售员': {
          data = this.$refs.userSelect.getSelected()
          if (data.length > 0) {
            this.state.condition.salesName = data[0].full_name
            this.state.condition.salesId = data[0].user_id
            this.$forceUpdate()
          }
          break
        }
        case '创建人': {
          data = this.$refs.userSelect.getSelected()
          if (data.length > 0) {
            this.state.condition.creatorName = data[0].full_name
            this.state.condition.creator = data[0].user_id
            this.$forceUpdate()
          }
          break
        }
        case '终端客户': {
          data = this.$refs.companySelect.getSelected()
          if (data.length > 0) {
            this.state.condition.channelName = data[0].company_name
            this.state.condition.channelId = data[0].company_id
            this.$forceUpdate()
          }
          break
        }
        case '团队': {
          data = this.$refs.storeList.getSelected()
          if (data.length > 0) {
            this.state.condition.storeName = data[0].store_name
            this.state.condition.storeid = data[0].store_id
            this.$forceUpdate()
          }
          break
        }

        default: {
          break
        }
      }
    },
    searchByCondition() {
      this.refetchData()
    },
    resetCondition() {
      this.state.condition = {}
      store.commit('presalesorder/updateCondition', this.state.condition)
      this.refetchData()
    },
    onRowSelected(items) {
      this.selected = items
    },
    selectAllRows() {
      this.$refs.refListTable.selectAllRows()
    },
    clearSelected() {
      this.$refs.refListTable.clearSelected()
    },
    showClerkMemo(item) {
      this.selectedItem = item
      this.remarkInfo = item.clerk_memo
      this.$refs['clerkMemoModal'].show()
    }
    ,
  },
  setup() {
    const toast = useToast()
    const router = useRouter()
    // Register module
    if (!store.hasModule('salesorder')) store.registerModule('salesorder', salesorderStore)
    if (!store.hasModule('presalesorder')) store.registerModule('presalesorder', presalesorderStore)
    if (!store.hasModule('operationlimit')) store.registerModule('operationlimit', operationlimitStore)
    if (!store.hasModule('presalesorderitem')) store.registerModule('presalesorderitem', presalesorderitemStore)
    if (!store.hasModule('store')) store.registerModule('store', storeStore)
    if (!store.hasModule('company')) store.registerModule('company', companyStore)
    if (!store.hasModule('storesales')) store.registerModule('storesales', storesalesStore)
    if (!store.hasModule('offlinesalesorder')) store.registerModule('offlinesalesorder', salesorderStore)
    if (!store.hasModule('lodop')) store.registerModule('lodop', lodopStore)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('salesorder')) store.unregisterModule('salesorder')
      if (store.hasModule('presalesorder')) store.unregisterModule('presalesorder')
      if (store.hasModule('operationlimit')) store.unregisterModule('operationlimit')
      if (store.hasModule('presalesorderitem')) store.unregisterModule('presalesorderitem')
      if (store.hasModule('store')) store.unregisterModule('store')
      if (store.hasModule('company')) store.unregisterModule('company')
      if (store.hasModule('storesales')) store.unregisterModule('storesales')
      if (store.hasModule('offlinesalesorder')) store.unregisterModule('offlinesalesorder')
      if (store.hasModule('lodop')) store.unregisterModule('lodop')
    })
    const state = reactive({
      condition: {},
    })

    onMounted(() => {
      state.condition = store.getters['presalesorder/getCondition']
    })
    const advanced_search = function () {
      this.advanced_search_modal = this.advanced_search_modal ? false : true
    }
    const fromChildren = function (params, modal) {
      for (let i = 0; i < modal.length; i++) {
        this.state.condition[modal[i]] = params == null ? null : params[modal[i]]
      }
      this.$forceUpdate()
    }


    const getLoad = function (params) {
      this.company_name_load = params == null ? null : params['company_name_load']
      this.company_id_load = params == null ? null : params['company_id_load']
      this.$forceUpdate()
    }

    const getTransport = function (params) {
      this.company_name_transport = params == null ? null : params['company_name_transport']
      this.company_id_transport = params == null ? null : params['company_id_transport']
      this.$forceUpdate()
    }

    const storeInput = function (event) {
      this.storeId = event.store_id
      this.store = event.label
      this.storecompanyid = event.store_company_id

    }

    const changeStatus = function (params, toStatus, information) {
      if (information === '驳回') {
        if (!isEmpty(params.bbm_ful_fillment_order_id)) {
          this.preSalesOrderId = params.order_id
          this.showBbRejectModal(1, this)
        } else {
          store.dispatch('presalesorder/changeStatus', {
            id: params.order_id,
            status: toStatus,
          }).then(res => {
            if (res.data.code === 0) {
              toast.success('操作成功')
              this.refetchData()
            } else {
              toast.error(res.data.data)
              this.refetchData()
            }
          })
        }

      }
      // 撤销审核
      if (information === '撤销审核') {
        if (!isEmpty(params.bbm_ful_fillment_order_id)) {
          this.preSalesOrderId = params.order_id
          this.showBbRejectModal(2, this)
        } else {
          store.dispatch('presalesorder/cancelAudit', {
            id: params.order_id,
          }).then(res => {
            if (res.data.code === 0) {
              toast.success('操作成功')
              this.refetchData()
            } else {
              toast.error(res.data.data)
              this.refetchData()
            }
          })
        }

      }

      if (information === '提交审核' || information === '作废') {
        store.dispatch('presalesorder/changeStatus', {
          id: params.order_id,
          status: toStatus,
        }).then(res => {
          if (res.data.code === 0) {
            toast.success('操作成功')
            this.refetchData()
          } else {
            toast.error(res.data.data)
          }
        })
      }

      if (information === '同意') {
        let orderNo = params.order_no
        let isExitZeroSalesPrice = false
        let isExitFlollZeroGrossProfit = false
        let orderItem = params.ext.items
        let clerkMemo = params.clerk_memo

        if (orderItem.length > 0) {
          for (let j = 0; j < orderItem.length; j++) {
            let salesPrice = orderItem[j].sales_price
            let grossProfit = orderItem[j].ext.grossProfit
            if (salesPrice == 0) {
              isExitZeroSalesPrice = true
            }
            if (isEmpty(clerkMemo) && isNumber(grossProfit) < 0) {
              isExitFlollZeroGrossProfit = true
            }
          }
        }

        if (isExitFlollZeroGrossProfit) {
          toast.error(orderNo + '订货单存在负毛利商品，请填写文员批注')
          return
        } else {
          if (isExitZeroSalesPrice) {
            if (confirm('明细存在单价为0的商品，是否审核通过')) {
              this.$refs['loadingModal'].show()
              store.dispatch('presalesorder/checkSuccess', {id: params.order_id}).then(res => {
                if (res.data.code === 0) {
                  // store.dispatch('presalesorder/pushPresalesOrderToBBMall', {id: params.order_id}).then(res => {
                  //   if (res.data.code === 0) {
                  //     toast.success('推送成功')
                  //     this.refetchData()
                  //   } else {
                  //     console.log(res.data.data)
                  //     this.refetchData()
                  //   }
                  // })
                  toast.success('操作成功')
                  this.refetchData()
                } else {
                  toast.error(res.data.data)
                  this.refetchData()
                }
                this.$refs['loadingModal'].hide()
              })
            }

          } else {
            this.$refs['loadingModal'].show()
            store.dispatch('presalesorder/checkSuccess', {id: params.order_id}).then(res => {
              if (res.data.code === 0) {
                // store.dispatch('presalesorder/pushPresalesOrderToBBMall', {id: params.order_id}).then(res => {
                //   if (res.data.code === 0) {
                //     toast.success('推送成功')
                //     this.refetchData()
                //   } else {
                //     console.log(res.data.data)
                //     this.refetchData()
                //   }
                // })
                toast.success('操作成功')
                this.refetchData()
              } else {
                toast.error(res.data.data)
                this.refetchData()
              }
              this.$refs['loadingModal'].hide()
            })
          }
        }

      }

    }

    const deliveryNote = function (index) {
      this.selectIndex = index
      if (this.selected.length == 0) {
        toast.error('请选择前置销售单!')
        return false
      }

      const idArray = []
      const setWarehouse = new Set()
      const warehouseArray = []
      for (let i = 0; i < this.selected.length; i++) {
        idArray.push(this.selected[i].order_id)
        setWarehouse.add(this.selected[i].sales_warehouse)
        warehouseArray.push(this.selected[i].sales_warehouse)
        if (this.selected[i].status != 2) {
          toast.error('只有审核通过状态才能打印!')
          return false
        }
      }

      if (setWarehouse.size != 1&&isEmpty(this.warehouseHead)) {
        if (confirm("所选订货单仓库不一致，是否继续")){
          var promise = Promise.resolve()

          if (index == 1) {
            for (let i = 0; i < this.selected.length; i++) {
              promise = promise.then(() => {
                return deliveryNotePrint(this.selected[i], this, warehouseArray.join(","))
              })
            }
          }

          if (index == 2) {
            for (let i = 0; i < this.selected.length; i++) {
              promise = promise.then(() => {
                return deliveryNotePrintTwo(this.selected[i], this, warehouseArray.join(","))
              })
            }
          }

          promise.then(() => {
            this.selected = this.selected.filter(item => item.is_checkout != 1)
            let _this = this
            if (_this.selected.length != 0) {
              if (confirm('是否将打印的' + isNumber(_this.selected.length) + '个销售单批量出库?')) {
                if (_this.selected.length == 0) {
                  toast.error('请选择前置销售单!')
                  return false
                }
                for (let i = 0; i < _this.selected.length; i++) {
                  if (_this.selected[i].status != 2) {
                    toast.error('只有审核通过状态才能出库!')
                    return false
                  }
                  if (_this.selected[i].is_checkout == 1) {
                    toast.error('请选择未出库的前置销售单!')
                    return false
                  }
                }
                var promiseT = Promise.resolve()
                for (let i = 0; i < _this.selected.length; i++) {
                  _this.$refs['loadingModal'].show()
                  promiseT = promiseT.then(() => {
                    return outbound(_this.selected[i], _this)
                  })
                }
                promiseT.then(() => {
                  refetchData()
                  _this.$refs['loadingModal'].hide()
                  _this.clearSelected()
                  _this.refetchData()
                  _this.cleanWarehouseHeadArray()
                })
              } else {
                refetchData()
                _this.$refs['loadingModal'].hide()
                _this.clearSelected()
                _this.refetchData()
                _this.cleanWarehouseHeadArray()
              }
            } else {
              refetchData()
              _this.$refs['loadingModal'].hide()
              _this.clearSelected()
              _this.refetchData()
              _this.cleanWarehouseHeadArray()
            }
          })
        }
      }else {
        var promise = Promise.resolve()

        if (index == 1) {
          for (let i = 0; i < this.selected.length; i++) {
            promise = promise.then(() => {
              return deliveryNotePrint(this.selected[i], this, warehouseArray.join(","))
            })
          }
        }

        if (index == 2) {
          for (let i = 0; i < this.selected.length; i++) {
            promise = promise.then(() => {
              return deliveryNotePrintTwo(this.selected[i], this, warehouseArray.join(","))
            })
          }
        }

        promise.then(() => {
          this.selected = this.selected.filter(item => item.is_checkout != 1)
          let _this = this
          if (_this.selected.length != 0) {
            if (confirm('是否将打印的' + isNumber(_this.selected.length) + '个销售单批量出库?')) {
              if (_this.selected.length == 0) {
                toast.error('请选择前置销售单!')
                return false
              }
              for (let i = 0; i < _this.selected.length; i++) {
                if (_this.selected[i].status != 2) {
                  toast.error('只有审核通过状态才能出库!')
                  return false
                }
                if (_this.selected[i].is_checkout == 1) {
                  toast.error('请选择未出库的前置销售单!')
                  return false
                }
              }
              var promiseT = Promise.resolve()
              for (let i = 0; i < _this.selected.length; i++) {
                _this.$refs['loadingModal'].show()
                promiseT = promiseT.then(() => {
                  return outbound(_this.selected[i], _this)
                })
              }
              promiseT.then(() => {
                refetchData()
                _this.$refs['loadingModal'].hide()
                _this.clearSelected()
                _this.refetchData()
                _this.cleanWarehouseHeadArray()
              })
            } else {
              refetchData()
              _this.$refs['loadingModal'].hide()
              _this.clearSelected()
              _this.refetchData()
              _this.cleanWarehouseHeadArray()
            }
          } else {
            refetchData()
            _this.$refs['loadingModal'].hide()
            _this.clearSelected()
            _this.refetchData()
            _this.cleanWarehouseHeadArray()
          }
        })
      }

    }

    const deliveryNotePrint = function (printobject, _this,warehouseArray) {
      return new Promise((resolve, reject) => {
        const idArray = []
        const setWarehouse = new Set()
        idArray.push(printobject.order_id)
        setWarehouse.add(printobject.sales_warehouse)

        const ids = idArray.join(',')
        store.dispatch('lodop/createPreSalesOrderDeliveryLODOP', {
          ids: ids,
          count: _this.count,
          warehouseIds:warehouseArray
        }).then(res => {
          if (res.data.code === 0) {
            _this.count = 0
            const data = res.data.data
            var LODOP = getLodop()
            if (LODOP == undefined) {
              toast.error('打印插件未安装，请安装插件!')
              return
            }
            LODOP.PRINT_INIT('打印送货单') //打印初始化
            //LODOP.SET_PRINTER_INDEX(printer) //选择的打印机类型（注意这里是打印机下标，0开始的）
            LODOP.SET_PRINT_STYLE('FontSize', 18) //设置对象风格
            // this.LODOP.ADD_PRINT_TEXT(50, 521, 130, 39, this.description) //增加纯文本项
            if (data[0].ext.page == 2) {
              LODOP.SET_PRINT_PAGESIZE(1, 2200, 1400, '') //设定纸张大小
            }
            if (data[0].ext.page == 3) {
              LODOP.SET_PRINT_PAGESIZE(1, 2200, 930, '') //设定纸张大小
            }
            LODOP.SET_PREVIEW_WINDOW(2, 2, 0, 0, 0, '')//设置窗口
            var html = ''
            for (let i = 0; i < data.length; i++) {
              if (data[i].ext.warehouseHeadList.length > 1 && isEmpty(_this.warehouseHead)) {
                _this.count += 1
                _this.warehouseHeadArray.length = 0
                const warehouseHeadList = data[i].ext.warehouseHeadList
                for (let j = 0; j < warehouseHeadList.length; j++) {
                  _this.warehouseHeadArray.push({
                    'label': warehouseHeadList[j].head_info,
                    'head_id': warehouseHeadList[j].head_id,
                  })
                }
                _this.$refs['warehouseHeadModal'].show()
                return
              }
              var item = ``
              var items = data[i].ext.item
              for (let j = 0; j < items.length; j++) {
                var proclass = 'proname14'
                var txtlength = parseInt(items[j].name.length)
                if (txtlength > 23 && txtlength <= 25) {
                  proclass = 'proname13'
                } else if (txtlength > 25 && txtlength <= 27) {
                  proclass = 'proname12'
                } else if (txtlength > 27 && txtlength <= 29) {
                  proclass = 'proname11'
                } else if (txtlength > 29 && txtlength <= 31) {
                  proclass = 'proname10'
                } else if (txtlength > 31 && txtlength <= 33) {
                  proclass = 'proname9'
                } else if (txtlength > 33) {
                  proclass = 'proname8'
                }
                item += ` <tr height="23px">
                        <td align="center">${items[j].ext.no}</td>
                        <td align="center">${items[j].ext.code}</td>
                        <td align="left"><span class="${proclass}">
                    ${(items[j].name.length > 45)
                    ? items[j].name.substring(0, 45)
                    : items[j].name}
                    </span></td>
<!--                     <td align="center">${items[j].stock_year}-${items[j].stock_month}</td>-->
<!--                        <td align="center">${items[j].ext.specification}</td>-->
                        <td align="center">${items[j].ext.product_unit}</td>
                        <td align="center">${items[j].sales_qty}</td>
                        <td align="right">${isEmpty(items[j].is_expenses)?items[j].sales_price.toFixed(2):'-'}</td>
                        <td align="right">${isEmpty(items[j].is_expenses)?items[j].ext.subtotal:'-'}</td>
                        <td align="center">${items[j].memo == null ? '' : items[j].memo}</td>
                    </tr>`
              }
              html = `
                <div style="width:740px">
                <table width="100%" border="0" cellspacing="0" cellpadding="0">
                    <tr height="10px">
                        <td colspan="2">
                            <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                <tr height="10px">
                                    <td width="30%">${data[i].ext.time} (第${isEmpty(data[i].ext.printTime)?1:data[i].ext.printTime}次)  ${data[i].order_no}</td>
                                    <td width="40%" align="center">&nbsp;</td>
                                    <td width="30%" align="right">&nbsp;</td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr height="10px">
                        <td colspan="2" valign="top">
                            <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                <tr height="10px">
                                    <td width="186" align="center" valign="bottom"></td>
                                    <td align="center"><font face="楷体" size="5" style="font-weight: bold">${data[i].ext.warehouseHeadList.length ==
              0 ? '' : (data[i].ext.warehouseHeadList.length == 1
                  ? data[i].ext.warehouseHeadList[0].head_info
                  : _this.warehouseHead)}销售单</font></td>
                                    <td width="180">&nbsp;</td>
                                </tr>
                                <tr>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr height="20px">
                        <td>客户：${data[i].ext.CName}</td>
                        <td>收货人：${data[i].ext.contactName}${data[i].ext.contactMobile}</td>
                    </tr>
                    <tr height="20px">
                        <td width="50%">地址：${data[i].ext.address == null ? '' : data[i].ext.address.replace('江苏苏州市工业园区',
                  '')}</td>
                        <td width="50%">
                            <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                <tr>
                                    <td align="left">付款周期：${getCodeLabel('preorder_pay_preiod', data[i].pay_period)}</td>
                                    <td width="150" align="right"></td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr height="20px">
                        <td>备注：${data[i].memo == undefined ? '' : data[i].memo}</td>
                    </tr>
                </table>
                <table width="100%" class="protable">
                    <tr height="25px">
                        <td width="4%" align="center">序号</td>
                        <td width="12%" align="center">69码</td>
                        <td align="center">商品名称</td>
<!--                        <td align="center">生产日期</td>-->
<!--                        <td width="8%" align="center">规格</td>-->
                        <td width="5%" align="center">单位</td>
                        <td width="6%" align="center">数量</td>
                        <td width="7%" align="center">单价</td>
                        <td width="10%" align="center">金额</td>
                        <td width="8%" align="center">备注</td>
                    </tr>
                    ${item}
                    <tr height="23px">
                        <td align="center"></td>
                        <td align="center">合计</td>
                        <td align="center">${data[i].ext.allPriceString}</td>
<!--                        <td align="center"></td>-->
<!--                        <td align="center"></td>-->
                        <td align="center"></td>
                        <td align="center">${data[i].ext.allQtySheet}</td>
                        <td align="center"></td>
                        <td align="center">¥${data[i].ext.subtotal}</td>
                        <td align="center"></td>
                    </tr>
                </table>
                <table width="100%" border="0" cellspacing="0" cellpadding="0">
                    <tr height="20px">
                        <td colspan="3">
                          <table width="100%" border="0" cellspacing="0" cellpadding="0">
                              <tr height="25px">
                                <td width="50%">卖方仓库：${data[i].ext.warehouse.location}</td>
                                <td width="25%">监督电话：${data[i].ext.warehouse.mobile}</td>
                                <td width="25%">业务员：${data[i].ext.salesUser.full_name + data[i].ext.salesUser.mobile}</td>
                              </tr>
                          </table>
                        </td>
                    </tr>
                    <tr height="20px">
                        <td colspan="3">
                          <table width="100%" border="0" cellspacing="0" cellpadding="0">
                              <tr height="20px">
                                <td width="25%">制单人：${data[i].ext.salesUser.full_name}</td>
                                <td width="25%">车型： </td>
                                <td width="25%">车牌号码： </td>
                                <td width="25%">司机签字：</td>
                              </tr>
                          </table>
                        </td>
                    </tr>
                    <tr height="50px">
                        <td>发货人签字：</td>
                        <td>审核人签字：</td>
                        <td>收货人签字：</td>
                    </tr>
                </table>
                <table width="100%" border="0" cellspacing="0" cellpadding="0">
                    <tr height="25px">
                        <td align="center"><font size="2px" style="font-weight: bold">**友情提醒：货品请当面点清，如购销双方未签订合同，此销售单视为合同**</font></td>
                    </tr>
                    <tr height="25px">
                        <td align="center"><font size="2px">**白联：存根 | 红联：客户 | 蓝联：财务 | 绿联：司机 | 黄联：仓管**</font></td>
                    </tr>
                </table>
                </div>
          `
              //条码 Y轴 X轴
              //const barpath = "/api/qrcode/barcode?content="+data[i].order_no.substring(4)+"&txtcontent="+data[i].order_no;
              //LODOP.ADD_PRINT_IMAGE(30, 0, 300, 100, `<img border="0" src="${barpath}" width="300" height="100">` + `</img`);
              // LODOP.ADD_PRINT_BARCODE(50, 10, 186, 36, '128Auto', data[i].order_no)
              // LODOP.SET_PRINT_STYLEA(0, 'ShowBarText', 0)//设置是否显示下方的文字
              //二维码 Y轴 X轴
              //const qrpath = data[i].ext.qrurl
              //LODOP.ADD_PRINT_IMAGE(25, 642, 100, 100, `<img border="0" src="${qrpath}" width="100" height="100">` + `</img`);

              LODOP.SET_PRINT_STYLEA(0, 'Stretch', 2)//(可变形)扩展缩放模式
              var style =
                  '<style>' +
                  'table{}' +
                  'table td{font-size: 14px;white-space: nowrap;}' +
                  '.protable{border-collapse: collapse;border: none;}' +
                  '.protable td{border: solid #000 1px;font-size: 18px}' +
                  '.proname14{font-size: 18px}' +
                  '.proname13{font-size: 17px}' +
                  '.proname12{font-size: 16px}' +
                  '.proname11{font-size: 15px}' +
                  '.proname10{font-size: 14px}' +
                  '.proname9{font-size: 13px}' +
                  '.proname8{font-size: 10px}' +
                  '.proname7{font-size: 7px}' +
                  '.proname6{font-size: 6px}' +
                  '.proname5{font-size: 5px}' +
                  '</style>'
              lodopWatermark(LODOP)
              LODOP.ADD_PRINT_HTM(0, 0, 1500, 1500, style + '<body size="10px">' + html + '</body>') // 增加超文本项（打印内容即为body内容）
              // LODOP.ADD_PRINT_HTM(0,400,300,100,"<font style='font-size:12px' format='ChineseNum'><span tdata='pageNO'>第##页</span>/<span tdata='pageCount'>共##页</span></font>");
              // LODOP.SET_PRINT_STYLEA(0,"ItemType",1);
              // if (data[i].ext.page == 2) {
              //   if (data[i].ext.item.length > 10) {
              //     LODOP.NewPage();
              //     const page = parseInt((data[i].ext.item.length - 10) / 21)
              //     for (let j = 0; j < page; j++) {
              //       LODOP.NewPage();
              //     }
              //   }
              // }
              // if (data[i].ext.page == 3) {
              //   if (data[i].ext.item.length > 6) {
              //     LODOP.NewPage();
              //     const page = parseInt((data[i].ext.item.length - 6) / 14)
              //     for (let j = 0; j < page; j++) {
              //       LODOP.NewPage();
              //     }
              //   }
              // }
              // LODOP.SET_PRINT_STYLEA(i+1,"AngleOfPageInside",-90);

            }
            // LODOP.ADD_PRINT_HTM(88, 20, 1500, 1500, '<body>' + html + '</body>')
            //  LODOP.PREVIEW() // 设置预览（如果使用预览，每次打印前会弹出预览窗口，根据需要决定是否使用）
            LODOP.PRINT()
            resolve()
          } else {
            toast.error(res.data.data)
          }
        })
      })

    }

    const deliveryNotePrintTwo = function (printobject, _this,warehouseArray) {
      return new Promise((resolve, reject) => {
        const idArray = []
        const setWarehouse = new Set()
        idArray.push(printobject.order_id)
        setWarehouse.add(printobject.sales_warehouse)

        const ids = idArray.join(',')
        store.dispatch('lodop/createPreSalesOrderDeliveryLODOP', {
          ids: ids,
          count: _this.count,
          warehouseIds:warehouseArray
        }).then(res => {
          if (res.data.code === 0) {
            _this.count = 0
            const data = res.data.data
            var LODOP = getLodop()
            if (LODOP == undefined) {
              toast.error('打印插件未安装，请安装插件!')
              return
            }
            LODOP.PRINT_INIT('打印送货单') //打印初始化
            //LODOP.SET_PRINTER_INDEX(printer) //选择的打印机类型（注意这里是打印机下标，0开始的）
            LODOP.SET_PRINT_STYLE('FontSize', 18) //设置对象风格
            // this.LODOP.ADD_PRINT_TEXT(50, 521, 130, 39, this.description) //增加纯文本项
            if (data[0].ext.page == 2) {
              LODOP.SET_PRINT_PAGESIZE(1, 2200, 1400, '') //设定纸张大小
            }
            if (data[0].ext.page == 3) {
              LODOP.SET_PRINT_PAGESIZE(1, 2200, 930, '') //设定纸张大小
            }
            LODOP.SET_PREVIEW_WINDOW(2, 2, 0, 0, 0, '')//设置窗口
            var html = ''
            for (let i = 0; i < data.length; i++) {
              if (data[i].ext.warehouseHeadList.length > 1 && isEmpty(_this.warehouseHead)) {
                _this.count += 1
                _this.warehouseHeadArray.length = 0
                const warehouseHeadList = data[i].ext.warehouseHeadList
                for (let j = 0; j < warehouseHeadList.length; j++) {
                  _this.warehouseHeadArray.push({
                    'label': warehouseHeadList[j].head_info,
                    'head_id': warehouseHeadList[j].head_id,
                  })
                }
                _this.$refs['warehouseHeadModal'].show()
                return
              }
              var item = ``
              var items = data[i].ext.item
              for (let j = 0; j < items.length; j++) {
                var proclass = 'proname14'
                var txtlength = parseInt(items[j].name.length)
                if (txtlength > 28 && txtlength <= 30) {
                  proclass = 'proname13'
                } else if (txtlength > 30 && txtlength <= 32) {
                  proclass = 'proname12'
                } else if (txtlength > 32 && txtlength <= 34) {
                  proclass = 'proname11'
                } else if (txtlength > 34 && txtlength <= 36) {
                  proclass = 'proname10'
                } else if (txtlength > 36 && txtlength <= 40) {
                  proclass = 'proname9'
                } else if (txtlength > 40) {
                  proclass = 'proname8'
                }
                item += ` <tr height="23px">
                        <td align="center">${items[j].ext.no}</td>
                        <td align="center">${items[j].ext.code}</td>
                        <td align="left"><span class="${proclass}">${(items[j].name.length > 45)
                    ? items[j].name.substring(0, 45)
                    : items[j].name}</span></td>
<!--                          <td align="center">${items[j].stock_year}-${items[j].stock_month}</td>-->
                        <td align="center">${items[j].ext.specification}</td>
                        <td align="center">${items[j].ext.product_unit}</td>
                        <td align="center">${isNumber(items[j].box_quantity) == 0 ? '' : items[j].box_quantity}</td>
                        <td align="center">${isNumber(items[j].box_quantity) == 0 ? '' : Math.ceil(
                    items[j].sales_qty / items[j].box_quantity)}</td>
                        <td align="center">${items[j].sales_qty}</td>
                        <td align="center">${items[j].memo == null ? '' : items[j].memo}</td>
                    </tr>`
              }
              html = `
                <div style="width:740px">
                <table width="100%" border="0" cellspacing="0" cellpadding="0">
                    <tr height="30px">
                        <td colspan="2">
                            <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                <tr height="30px">
                                    <td width="30%">${data[i].ext.time} (第${isEmpty(data[i].ext.printTime)?1:data[i].ext.printTime}次)</td>
                                    <td width="40%" align="center">&nbsp;</td>
                                    <td width="30%" align="right">&nbsp;</td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr height="80px">
                        <td colspan="2" valign="top">
                            <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                <tr height="70px">
                                    <td width="186" align="center" valign="bottom">${data[i].order_no}</td>
                                    <td align="center"><font face="楷体" size="4" style="font-weight: bold">${data[i].ext.warehouseHeadList.length ==
              0 ? '' : (data[i].ext.warehouseHeadList.length == 1
                  ? data[i].ext.warehouseHeadList[0].head_info
                  : _this.warehouseHead)}销售单</font></td>
                                    <td width="180">&nbsp;</td>
                                </tr>
                                <tr>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr height="20px">
                        <td colspan="2">客户：${data[i].ext.CName}</td>
                    </tr>
                    <tr height="20px">
                        <td width="50%">地址：${data[i].ext.address == null ? '' : data[i].ext.address.replace('江苏苏州市工业园区',
                  '')}</td>
                        <td width="50%">
                            <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                <tr>
                                    <td align="left">付款周期：${getCodeLabel('preorder_pay_preiod', data[i].pay_period)}</td>
                                    <td width="150" align="right"></td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr height="20px">
                        <td>收货人：${data[i].ext.contactName}${data[i].ext.contactMobile}</td>
                        <td>备注：${data[i].memo == undefined ? '' : data[i].memo}</td>
                    </tr>
                </table>
                <table width="100%" class="protable">
                    <tr height="25px">
                        <td width="4%" align="center">序号</td>
                        <td width="12%" align="center">69码</td>
                        <td align="center">商品名称</td>
<!--                        <td align="center">生产日期</td>-->
                        <td width="8%" align="center">规格</td>
                        <td width="5%" align="center">单位</td>
                        <td width="6%" align="center">箱规</td>
                        <td width="7%" align="center">箱数</td>
                        <td width="10%" align="center">数量</td>
                        <td width="8%" align="center">备注</td>
                    </tr>
                    ${item}
                    <tr height="23px">
                        <td align="center"></td>
                        <td align="center">合计</td>
                        <td align="center"></td>
                        <td align="center"></td>
                        <td align="center"></td>
                        <td align="center"></td>
<!--                        <td align="center"></td>-->
                        <td align="center">${isNumber(data[i].ext.allBoxQuantityQty) == 0
                  ? ''
                  : data[i].ext.allBoxQuantityQty}</td>
                        <td align="center">${data[i].ext.allQtySheet}</td>
                        <td align="center"></td>
                    </tr>
                </table>
                <table width="100%" border="0" cellspacing="0" cellpadding="0">
                    <tr height="20px">
                        <td colspan="3">
                          <table width="100%" border="0" cellspacing="0" cellpadding="0">
                              <tr height="25px">
                                <td width="50%">卖方仓库：${data[i].ext.warehouse.location}</td>
                                <td width="25%">监督电话：${data[i].ext.warehouse.mobile}</td>
                                <td width="25%">业务员：${data[i].ext.salesUser.full_name + data[i].ext.salesUser.mobile}</td>
                              </tr>
                          </table>
                        </td>
                    </tr>
                    <tr height="20px">
                        <td colspan="3">
                          <table width="100%" border="0" cellspacing="0" cellpadding="0">
                              <tr height="20px">
                                <td width="25%">制单人：${data[i].ext.salesUser.full_name}</td>
                                <td width="25%">车型： </td>
                                <td width="25%">车牌号码： </td>
                                <td width="25%">司机签字：</td>
                              </tr>
                          </table>
                        </td>
                    </tr>
                    <tr height="50px">
                        <td>发货人签字：</td>
                        <td>审核人签字：</td>
                        <td>收货人签字：</td>
                    </tr>
                </table>
                <table width="100%" border="0" cellspacing="0" cellpadding="0">
                    <tr height="25px">
                        <td align="center"><font size="2px" style="font-weight: bold">**友情提醒：货品请当面点清，如购销双方未签订合同，此销售单视为合同**</font></td>
                    </tr>
                    <tr height="25px">
                        <td align="center"><font size="2px">**白联：存根 | 红联：客户 | 蓝联：财务 | 绿联：司机 | 黄联：仓管**</font></td>
                    </tr>
                </table>
                </div>
          `
              //条码 Y轴 X轴
              //const barpath = "/api/qrcode/barcode?content="+data[i].order_no.substring(4)+"&txtcontent="+data[i].order_no;
              //LODOP.ADD_PRINT_IMAGE(30, 0, 300, 100, `<img border="0" src="${barpath}" width="300" height="100">` + `</img`);
              LODOP.ADD_PRINT_BARCODE(50, 10, 186, 36, '128Auto', data[i].order_no)
              LODOP.SET_PRINT_STYLEA(0, 'ShowBarText', 0)//设置是否显示下方的文字
              //二维码 Y轴 X轴
              //const qrpath = data[i].ext.qrurl
              //LODOP.ADD_PRINT_IMAGE(25, 642, 100, 100, `<img border="0" src="${qrpath}" width="100" height="100">` + `</img`);

              LODOP.SET_PRINT_STYLEA(0, 'Stretch', 2)//(可变形)扩展缩放模式
              var style =
                  '<style>' +
                  'table{}' +
                  'table td{font-size: 14px;white-space: nowrap;}' +
                  '.protable{border-collapse: collapse;border: none;}' +
                  '.protable td{border: solid #000 1px;}' +
                  '.proname14{font-size: 14px}' +
                  '.proname13{font-size: 13px}' +
                  '.proname12{font-size: 12px}' +
                  '.proname11{font-size: 11px}' +
                  '.proname10{font-size: 10px}' +
                  '.proname9{font-size: 9px}' +
                  '.proname8{font-size: 8px}' +
                  '.proname7{font-size: 7px}' +
                  '.proname6{font-size: 6px}' +
                  '.proname5{font-size: 5px}' +
                  '</style>'
              lodopWatermark(LODOP)
              LODOP.ADD_PRINT_HTM(0, 0, 1500, 1500, style + '<body size="10px">' + html + '</body>') // 增加超文本项（打印内容即为body内容）
              // LODOP.ADD_PRINT_HTM(0,400,300,100,"<font style='font-size:12px' format='ChineseNum'><span tdata='pageNO'>第##页</span>/<span tdata='pageCount'>共##页</span></font>");
              // LODOP.SET_PRINT_STYLEA(0,"ItemType",1);
              // if (data[i].ext.page == 2) {
              //   if (data[i].ext.item.length > 10) {
              //     LODOP.NewPage();
              //     const page = parseInt((data[i].ext.item.length - 10) / 21)
              //     for (let j = 0; j < page; j++) {
              //       LODOP.NewPage();
              //     }
              //   }
              // }
              // if (data[i].ext.page == 3) {
              //   if (data[i].ext.item.length > 6) {
              //     LODOP.NewPage();
              //     const page = parseInt((data[i].ext.item.length - 6) / 14)
              //     for (let j = 0; j < page; j++) {
              //       LODOP.NewPage();
              //     }
              //   }
              // }
              // LODOP.SET_PRINT_STYLEA(i+1,"AngleOfPageInside",-90);

            }
            // LODOP.ADD_PRINT_HTM(88, 20, 1500, 1500, '<body>' + html + '</body>')
            //  LODOP.PREVIEW() // 设置预览（如果使用预览，每次打印前会弹出预览窗口，根据需要决定是否使用）
            LODOP.PRINT()
            resolve()
          } else {
            toast.error(res.data.data)
          }
        })
      })

    }

    const distribution = function () {
      if (this.selected.length == 0) {
        toast.error('请选择前置销售单!')
        return false
      }
      const idArray = []
      const setWarehouse = new Set()
      const mergeNoSet = new Set()
      for (let i = 0; i < this.selected.length; i++) {
        idArray.push(this.selected[i].order_id)
        setWarehouse.add(this.selected[i].sales_warehouse)
        mergeNoSet.add(this.selected[i].merge_no)
        if (this.selected[i].status != 2) {
          toast.error('只有审核通过状态才能打印!')
          return false
        }
      }
      if (setWarehouse.size != 1) {
        toast.error('请选择相同仓库的前置销售单!')
        return false
      }
      if (mergeNoSet.size != 1) {
        if (confirm('勾选的前置单包含不同配货单号,将会重置配货单号,是否继续生成?')) {
        } else {
          return
        }
      }
      const ids = idArray.join(',')
      store.dispatch('lodop/createPreSalesOrderDistributionLODOP', {
        ids: ids,
      }).then(res => {
        if (res.data.code === 0) {
          var LODOP = getLodop()
          if (LODOP == undefined) {
            toast.error('打印插件未安装，请安装插件!')
            return
          }
          LODOP.PRINT_INIT('打印配货单') //打印初始化
          //LODOP.SET_PRINTER_INDEX(printer) //选择的打印机类型（注意这里是打印机下标，0开始的）
          LODOP.SET_PRINT_STYLE('FontSize', 18) //设置对象风格
          // this.LODOP.ADD_PRINT_TEXT(50, 521, 130, 39, this.description) //增加纯文本项
          LODOP.SET_PRINT_PAGESIZE(1, 0, 0, 'A4') //设定纸张大小
          // LODOP.SET_PRINT_MODE('PRINT_PAGE_PERCENT', '55%')//设置缩放
          LODOP.SET_PREVIEW_WINDOW(2, 2, 0, 0, 0, '')//设置窗口

          const data = res.data.data.ext
          var item = ``
          for (let i = 0; i < data.item.length; i++) {
            const year = isEmpty(data.item[i].stock_year)?'':(""+data.item[i].stock_year).substring(2,4)
            item += `<tr height="23px">
                        <td align="center">${data.item[i].ext.no}</td>
                        <td align="center" style="font-size:12px;white-space: nowrap;">${data.item[i].ext.code}</td>
                        <td align="left" style="white-space: nowrap;">${data.item[i].name}</td>
                        <td align="left" style="white-space: nowrap;">${year}-${data.item[i].stock_month}</td>
                        <td align="center" style="font-size:12px;white-space: nowrap;">${data.item[i].ext.specification}</td>
                        <td align="center" style="font-size:12px;white-space: nowrap;">${data.item[i].ext.productUnit}</td>
                        <td align="center" style="white-space: nowrap;">${data.item[i].sales_qty}</td>
                        <td align="center" style="white-space: nowrap;">${data.item[i].ext.locationname}</td>
                        <td align="center" style="font-size:12px;white-space: nowrap;"></td>
                        </tr>`
          }

          var html = `
                <table width="740px" border="0" cellspacing="0" cellpadding="0">
                    <tr height="20px">
                        <td colspan="3">&nbsp;</td>
                    </tr>
                    <tr height="30px">
                        <td colspan="3" align="center"><font face="楷体" size="4">配货单</font></td>
                    </tr>
                    <tr height="25px">
                        <td width="370">仓库名称：${data.warehouseName}</td>
                        <td width="370">配货编号：${data.mergeNo}</td>
                    </tr>
                    <tr height="25px">
                        <td width="370">制单人：${data.user}</td>
                        <td width="370">打印时间：${data.time}</td>
                    </tr>
                </table>

                <table width="740px" border="1" cellspacing="0" cellpadding="0">
                    <tr height="25px">
                        <td width="40px" align="center">序号</td>
                        <td width="100px" align="center">69码</td>
                        <td width="260px" align="center">商品名称</td>
                        <td width="90px" align="center">生产日期</td>
                        <td width="50px" align="center">规格</td>
                        <td width="50px" align="center">单位</td>
                        <td width="80px" align="center">数量</td>
                        <td width="60px" align="center">库位</td>
                        <td width="60px" align="center">备注</td>
                    </tr>
                    ${item}
                    <tr height="23px">
                        <td align="center"></td>
                        <td align="left" style="font-size:12px;white-space: nowrap;"></td>
                        <td align="center" style="font-size:12px;white-space: nowrap;"></td>
                        <td align="center" style="font-size:12px;white-space: nowrap;"></td>
                        <td align="center" style="font-size:12px;white-space: nowrap;"></td>
                        <td align="center" style="font-size:12px;white-space: nowrap;">合计</td>
                        <td align="center" style="font-size:12px;white-space: nowrap;">${data.allQty}</td>
                        <td align="center" style="font-size:12px;white-space: nowrap;"></td>
                        <td align="center" style="font-size:12px;white-space: nowrap;"></td>
                    </tr>
                </table>
                    <table width="740px" border="0" cellspacing="0" cellpadding="0">
                    <tr height="50px">
                        <td width="246">仓管签字：</td>
                        <td width="246">审核签字：</td>
                        <td width="246">司机签字：</td>
                    </tr>
                    <tr height="25px">
                        <td colspan="3" align="center"><font face="楷体" size="2" style="font-weight: bold">**此单不作为出库依据，仅限仓库内部拣货使用**</font></td>
                    </tr>
                    <tr height="25px">
                        <td colspan="3" align="center"><font face="楷体" size="2" style="font-weight: bold">**友情提醒：货物请当面点清，事后恕不负责**</font></td>
                    </tr>
                </table>
          `
          lodopWatermark(LODOP)
          LODOP.ADD_PRINT_HTM(0, 0, 1500, 1500, '<body>' + html + '</body>') // 增加超文本项（打印内容即为body内容）
          LODOP.SET_PRINT_MODE('DOUBLE_SIDED_PRINT', 0)
          LODOP.PREVIEW() // 设置预览（如果使用预览，每次打印前会弹出预览窗口，根据需要决定是否使用）
          // LODOP.PRINT()
          this.refetchData()
        } else {
          toast.error(res.data.data)
        }
      })
      this.clearSelected()
    }

    const cleanWarehouseHeadArray = function () {
      this.warehouseHeadArray.length = 0
      this.warehouseHead = ''
    }

    const setWarehouseHead = function (event) {
      if (event != null) {
        this.warehouseHead = event.label
      } else {
        this.warehouseHead = null
      }
      this.$forceUpdate()
    }

    const checkQty = function (item) {
      if (item.refuse_qty >= 0 && item.refuse_qty <= item.sales_qty) {
        store.dispatch('presalesorderitem/modifyRefuseQty', {
          id: item.item_id,
          refuseQty: item.refuse_qty,
        }).then(res => {
          if (res.data.code === 0) {
            toast.success('修改成功')
          } else {
            toast.error('修改失败')
          }
        })
      } else {
        item.refuse_qty = ''
        toast.error('数量填写不符合要求,请重新填写!')
      }

    }

    const checkSalesQty = function (item) {
      if (item.sales_qty > 0) {
        store.dispatch('presalesorderitem/modifySalesQty', {
          id: item.item_id,
          salesQty: item.sales_qty,
          salesPrice: item.sales_price,
        }).then(res => {
          if (res.data.code === 0) {
            toast.success('修改成功')
          } else {
            toast.error('修改失败')
          }
        })
      } else {
        item.sales_qty = ''
        toast.error('数量/价格填写不符合要求,请重新填写!')
      }

    }

    const checkBoxQuantity = function (item) {
      if (isNumber(item.box_quantity) > 0) {
        const cRegExp = /^[0-9]\d*$/
        if (!cRegExp.test(item.box_quantity)) {
          toast.error('箱规必须是正整数!')
          item.box_quantity = ''
        } else {
          store.dispatch('presalesorderitem/saveBoxQuantity', {
            id: item.item_id,
            boxQuantity: item.box_quantity,
          }).then(res => {
            if (res.data.code === 0) {
              toast.success('修改成功')
            } else {
              toast.error('修改失败')
            }
          })
        }
      } else {
        toast.error('箱规填写错误！')
        item.box_quantity = ''
      }
    }



    const checkSalesPrice = function (item) {
      if (item.sales_price >= 0) {
        store.dispatch('presalesorderitem/modifySalesQty', {
          id: item.item_id,
          salesPrice: item.sales_price,
          salesQty: item.sales_qty,
        }).then(res => {
          if (res.data.code === 0) {
            toast.success('修改成功')
          } else {
            toast.error('修改失败')
          }
        })
      } else {
        item.sales_price = 0
        toast.error('数量/价格填写不符合要求,请重新填写!')
      }

    }

    const showReceiptAttachments = function (item) {
      this.orderId = item.order_id
      this.attachmentsObjectIdCh = item.order_id
      this.attachmentsStr = item.receipt_attachments
      this.$refs['remarkModal'].show()
    }

    const onUploadedExcel = function (id, attachment, result) {
      this.attachmentsId = result
    }

    const saveAttachments = function () {
      store.dispatch('presalesorder/addAttachments', {
        id: this.orderId,
        attachmentsId: this.attachmentsId,
      }).then(res => {
        if (res.data.code === 0) {
          toast.success('添加成功')
        } else {
          toast.error('添加失败')
        }
      })
    }

    const isReceiptMethod = function () {
      let isTrue = false
      let isTrue2 = false
      let productNum = 0
      if (this.selected.length == 0) {
        toast.error('请选择前置销售单!')
        return false
      }

      for (let i = 0; i < this.selected.length; i++) {
        let status = this.selected[i].status
        let isCheckOut = this.selected[i].is_checkout
        if (status != 2 || isCheckOut != 1) {
          toast.error('只有已审核且出库的单子才可以批量回执！')
          return false
        }
      }

      for (let i = 0; i < this.selected.length; i++) {
        if (!isEmpty(this.selected[i].bbm_ful_fillment_order_id)) {
          isTrue2 = true
        }
        let orderItem = this.selected[i].ext.items
        if (orderItem.length > 0) {
          for (let j = 0; j < orderItem.length; j++) {
            let refuseQty = orderItem[j].refuse_qty
            if (refuseQty > 0) {
              productNum = productNum + 1
              isTrue = true
            }
          }
        }
      }
      if (isTrue && isTrue2) {
        this.showBbRejectModal(3, this)
        return;
      }

      if (isTrue) {
        if (confirm('存在拒收商品' + productNum + '条，系统将自动创建退货单，是否继续？')) {
          for (let i = 0; i < this.selected.length; i++) {
            let orderItem = this.selected[i].ext.items
            let orderItemZero = orderItem.filter(function (e) {
              return e.refuse_qty > 0
            })
            if (orderItemZero.length > 0) {
              let orderId
              let orderItemId = []
              for (let j = 0; j < orderItemZero.length; j++) {
                orderId = orderItem[j].order_id
                orderItemId.push(orderItemZero[j].item_id)
              }
              // 调用方法

              store.dispatch('presalesorder/isReceiptError', {
                id: orderId,
                itemId: orderItemId.join(','),
              }).then(res => {
                if (res.data.code === 0) {
                  toast.success('保存成功!')
                } else {
                  toast.error(res.data.data)
                }
                store.dispatch('presalesorder/lockCheck', {
                  type: 3,
                  id: orderId,
                  itemId: orderItemId.join(','),
                  checkOrDel: 'del',
                })
                refetchData()
              })
              //
            }
          }
        }
      }
      if (!isTrue) {
        for (let i = 0; i < this.selected.length; i++) {
          let orderId = this.selected[i].order_id
          store.dispatch('presalesorder/isReceiptSuccess', {id: orderId}).then(res => {
            if (res.data.code === 0) {
              toast.success('保存成功')
              refetchData()
            } else {
              toast.error(res.data.data)
            }
          })
        }
      }
    }

    const allOutbound = function () {
      if (this.selected.length == 0) {
        toast.error('请选择前置销售单!')
        return false
      }
      for (let i = 0; i < this.selected.length; i++) {
        if (this.selected[i].status != 2) {
          toast.error('只有审核通过状态才能出库!')
          return false
        }
        if (this.selected[i].is_checkout == 1) {
          toast.error('请选择未出库的前置销售单!')
          return false
        }
      }
      //使用
      this.warehouseIdModel = this.selected[0].sales_warehouse
      for (let i = 0; i < this.selected.length; i++) {
        if (this.selected[0].sales_warehouse != this.selected[i].sales_warehouse) {
          toast.error('仓库名称不一致')
          return false
        }
      }
      //查询承运商
      axios.post('/api/company/searchLogistics', {
        'showAllLogistics': false, 'warehouseIdFrom': this.warehouseIdModel, 'warehouseIdTo': 0,
      }).then(res => {
        if (res.data.code === 0) {
          const list = res.data.data.list
          if (list.length > 0) {
            this.company_name_transport = list[list.length - 1].company_name
            this.company_id_transport = list[list.length - 1].company_id
            //this.$forceUpdate()
          } else {
            this.company_name_transport = ''
            this.company_id_transport = ''
          }
        }
      })
      //查询装卸队
      axios.post('/api/company/searchLoadTeam', {
        'showAllLogistics': false, 'warehouseId': this.warehouseIdModel,
      }).then(res => {
        if (res.data.code === 0) {
          const list = res.data.data.list
          if (list.length > 0) {
            this.company_name_load = list[list.length - 1].company_name
            this.company_id_load = list[list.length - 1].company_id
            //this.$forceUpdate()
          } else {
            this.company_name_load = ''
            this.company_id_load = ''
          }
        }
      })
      this.car_no = ''
      this.car_detail = ''
      this.$refs['companyModal'].show()

    }

    const submitInfo = function () {
      var promise = Promise.resolve()
      for (let i = 0; i < this.selected.length; i++) {
        this.$refs['loadingModal'].show()
        promise = promise.then(() => {
          return updatePreSalesOrder(this.selected[i], this)
        })
        promise = promise.then(() => {
          return outbound(this.selected[i], this)
        })
      }
      promise.then(() => {
        refetchData()
        this.$refs['loadingModal'].hide()
        this.$refs['companyModal'].hide()
      })
    }

    const updatePreSalesOrder = function (data, _this) {
      let company_name_transport = _this.company_name_transport
      let company_id_transport = _this.company_id_transport
      let company_name_load = _this.company_name_load
      let company_id_load = _this.company_id_load
      let car_detail = _this.car_detail
      let car_no = _this.car_no
      return new Promise((resolve, reject) => {
        //出库
        store.dispatch('presalesorder/savePreSalesOrder', {
          id: data.id,
          company_name_transport: company_name_transport,
          company_id_transport: company_id_transport,
          company_name_load: company_name_load,
          company_id_load: company_id_load,
          car_detail: car_detail,
          car_no: car_no
        }).then(response => resolve(response)).catch(error => reject(error))
      })
    }

    const outbound = function (data, _this) {
      return Promise.resolve(data).then((res) => {
        return new Promise((resolve, reject) => {
          //校验
          store.dispatch('operationlimit/psoCheck', {
            type: 1,
            orderId: res.order_id,
            checkOrDel: 'check',
          }).then(response => {
            if (response.data.code != 0) {
              toast.error(response.data.data)
            }
            resolve(res)
          })
        })
      }).then((res) => {
        return new Promise((resolve, reject) => {
          //出库
          store.dispatch('presalesorder/outbound', {id: res.order_id}).then(response => {
            if (response.data.code === 1) {
              toast.error(response.data.data)
            }
            if (response.data.code === 0) {
              //改状态
              toast.success('审核成功')
            }
            resolve(res)
          })
        })
      }).then((res) => {
        return new Promise((resolve, reject) => {
          //删除
          store.dispatch('operationlimit/psoCheck', {
            type: 1,
            orderId: res.order_id,
            checkOrDel: 'del',
          }).then(response => {
            resolve(res)
          })
        })
      })
    }


    const toURL = function (urlId) {
      //销售订单
      let routerData = this.$router.resolve({
        name: 'apps-offlinesalesorder-list',
        query: {id: urlId},
      })
      window.open(routerData.href, '_blank')
    }

    const deleteItem = function (data) {
      if (confirm('确认删除订货单明细？')) {
        store.dispatch('presalesorderitem/state', {
          id: data.item.item_id,
          state: 0,
        }).then(response => {
          if (response.data.code === 1) {
            toast.error(response.data.data)
          }
          if (response.data.code === 0) {
            //改状态
            toast.success('删除成功')
          }
          refetchData()
        })
      }

    }

    const exportData = function () {
      const condition = state.condition
      let statusClick = null
      if (selectedT.value == 6) { //待出库
        statusClick = 2
        condition.isCheckout = 0
      }
      if (selectedT.value == 7) {//待回执
        statusClick = 2
        condition.isCheckout = 1
        condition.is_receipt = 0
      }
      if (selectedT.value == 8) { //已完成
        statusClick = 2
        condition.is_receipt = 1
      }
      let statusFal = null
      if (statusClick == null) {
        statusFal = selectedT != null ? selectedT.value : null
      } else {
        statusFal = statusClick
      }
      const warehouseArray = []
      if (!isEmpty(condition.warehouseArray)){
        condition.warehouseArray.forEach((value, index, array) => {
          warehouseArray.push(value.value)
        })
      }
      const params = {
        isPrint: condition.isPrint,
        status: statusFal,
        warehouseId: warehouseArray.join(","),
        orderNo: condition.order_no,
        salesId: condition.sales_id,
        salesName: condition.sales_name,
        salesTime: condition.sales_time,
        channelId: condition.channelId,
        salesWarehouse: condition.sales_warehouse,
        isCheckout: condition.isCheckout,
        isCreateSalesOrder: condition.isCreateSalesOrder,
        storeName: condition.storeName,
        storeId: condition.storeid,
        productCondition: condition.productCondition,
        mergeNo: condition.mergeNo,
        addTime: condition.add_time,
        creator: condition.creator,
        isReceipt: condition.is_receipt,
        receiptType: condition.receipt_type,
        order_by: orderBy.value,
        order_desc: isSortDirDesc.value === true ? 'desc' : '',
        purchaseTeamId: isEmpty(condition.purchaseTeamId)?null:condition.purchaseTeamId.value
      }
      store.dispatch('presalesorder/exportData', params).then(res => {
        toast.success('导出成功')
        let data = new Blob([res.data], {type: 'application/vnd.ms-excel'})   //接收的是blob，若接收的是文件流，需要转化一下
        let filename = '销售订货' + new Date().getTime()
        /*-------------*/
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = URL.createObjectURL(data)
        link.setAttribute('download', filename + '.xlsx')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
    }

    const saveRemark = function () {
      this.selectedItem.clerk_memo = this.remarkInfo
      store.dispatch('presalesorder/saveRemark', {
        id: this.selectedItem.order_id,
        remark: this.selectedItem.clerk_memo,
      }).then(res => {
        if (res.data.code === 0) {
          toast.success('修改成功')
          refetchData()
        } else {
          toast.error('修改失败')
          refetchData()
        }
      })
    }

    const setBbmRefuseReason = function (event) {
      if (event != null) {
        this.bbmRefuseReason = event.value
        this.bbmRefuseReasonLabel = event.label
      } else {
        this.bbmRefuseReason = null
        this.bbmRefuseReasonLabel = null
      }

      this.$forceUpdate()
    }

    const showBbRejectModal = function (params, _this) {
      _this.bbmRefuseReason = null
      _this.bbmRefuseReasonLabel = null
      _this.reasonType = params
      _this.$refs['bbRejectModal'].show()
    }

    const bbConfirm = function () {
      if (isEmpty(this.bbmRefuseReason)) {
        toast.error("请选择原因!")
        return;
      }
      // console.log(this.bbmRefuseReason)
      // console.log(this.bbmRefuseReasonLabel)
      if (this.reasonType == 1) {
        store.dispatch('presalesorder/changeStatus', {
          id: this.preSalesOrderId,
          status: 5,
          bbmRefuseReason: this.bbmRefuseReason,
          bbmRefuseReasonLabel: this.bbmRefuseReasonLabel
        }).then(res => {
          if (res.data.code == 0) {
            toast.success('操作成功')
            this.refetchData()
          } else {
            toast.error(res.data.data)
          }
        })
      }
      if (this.reasonType == 2) {
        store.dispatch('presalesorder/cancelAudit', {
          id: this.preSalesOrderId,
          bbmRefuseReason: this.bbmRefuseReason,
          bbmRefuseReasonLabel: this.bbmRefuseReasonLabel
        }).then(res => {
          if (res.data.code === 0) {
            toast.success('操作成功')
            this.refetchData()
          } else {
            toast.error(res.data.data)
            this.refetchData()
          }
        })
      }
      if (this.reasonType == 3) {
        let isTrue = false
        let productNum = 0
        if (this.selected.length == 0) {
          toast.error('请选择前置销售单!')
          return false
        }

        for (let i = 0; i < this.selected.length; i++) {
          let status = this.selected[i].status
          let isCheckOut = this.selected[i].is_checkout
          if (status != 2 || isCheckOut != 1) {
            toast.error('只有已审核且出库的单子才可以批量回执！')
            return false
          }
        }

        for (let i = 0; i < this.selected.length; i++) {
          let orderItem = this.selected[i].ext.items
          if (orderItem.length > 0) {
            for (let j = 0; j < orderItem.length; j++) {
              let refuseQty = orderItem[j].refuse_qty
              if (refuseQty > 0) {
                productNum = productNum + 1
                isTrue = true
              }
            }
          }
        }

        if (isTrue) {
          if (confirm('存在拒收商品' + productNum + '条，系统将自动创建退货单，是否继续？')) {
            for (let i = 0; i < this.selected.length; i++) {
              let orderItem = this.selected[i].ext.items
              let orderItemZero = orderItem.filter(function (e) {
                return e.refuse_qty > 0
              })
              if (orderItemZero.length > 0) {
                let orderId
                let orderItemId = []
                for (let j = 0; j < orderItemZero.length; j++) {
                  orderId = orderItem[j].order_id
                  orderItemId.push(orderItemZero[j].item_id)
                }
                // 调用方法

                store.dispatch('presalesorder/isReceiptError', {
                  id: orderId,
                  itemId: orderItemId.join(','),
                  bbmRefuseReason: this.bbmRefuseReason,
                  bbmRefuseReasonLabel: this.bbmRefuseReasonLabel
                }).then(res => {
                  if (res.data.code === 0) {
                    toast.success('保存成功!')
                  } else {
                    toast.error(res.data.data)
                  }
                  store.dispatch('presalesorder/lockCheck', {
                    type: 3,
                    id: orderId,
                    itemId: orderItemId.join(','),
                    checkOrDel: 'del',
                  })
                  refetchData()
                })
                //

              }
            }
          }
        }
        if (!isTrue) {
          for (let i = 0; i < this.selected.length; i++) {
            let orderId = this.selected[i].order_id
            store.dispatch('presalesorder/isReceiptSuccess', {id: orderId}).then(res => {
              if (res.data.code === 0) {
                toast.success('保存成功')
                refetchData()
              } else {
                toast.error(res.data.data)
              }
            })
          }
        }
      }

    }

    const changeIsExpenses = function (event,params) {
      store.dispatch('presalesorderitem/changeIsExpenses', {
        itemId: params.id,
        isExpenses: event,
      }).then(response => {
        if (response.data.code === 1) {
          toast.error(response.data.data)
        }else {
          toast.success("修改成功")
          // if (event == 1){
          //   toast.success("费用生成")
          // }else {
          //   toast.success("费用删除")
          // }
        }
      })
    }
    const changeIsAdjustPrice = function (event,params) {
      store.dispatch('presalesorderitem/changeIsAdjustPrice', {
        itemId: params.id,
        isAdjustPrice: event,
      }).then(response => {
        if (response.data.code === 1) {
          toast.error(response.data.data)
        }else {
          toast.success("修改成功")
        }
      })
    }


    const changeSalesCostType = function (event, params) {
      params.sales_cost_type = isEmpty(event)?null:event.value
      this.$forceUpdate()
      store.dispatch('presalesorderitem/changeSalesCostType', {
        itemId: params.id,
        type: isEmpty(event)?null:event.value,
      }).then(response => {
        if (response.data.code === 1) {
          toast.error("修改失败")
        }
      })
    }

    const showChargeModal = function (param,type){
      this.preSalesOrderId = param.id
      this.warehouseIdModel = param.sales_warehouse
      if(type === 1){
        this.$refs['feeRef'].showLoadChargeModal(param.sales_warehouse,3)
      }
      if(type === 2){
        this.$refs['feeRef'].showTransportChargeModal(param.sales_warehouse,0,3)
      }
    }

    const allChangeStatus = function (params) {
      if (confirm('是否批量'+params)){
        if (this.selected.length == 0) {
          toast.error('请选择前置销售单!')
          return false
        }

        for (let i = 0; i < this.selected.length; i++) {
          let status = this.selected[i].status
          if (status != 1) {
            toast.error('只有待审核单据才可批量'+params+'！')
            return false
          }
          if (params === '驳回'&&!isEmpty(this.selected[i].bbm_ful_fillment_order_id)){
            toast.error(this.selected[i].order_no+'需要填写bbmall驳回原因，请手动驳回')
            return
          }
          if (params === '同意'){
            let orderNo = this.selected[i].order_no
            let isExitZeroSalesPrice = false
            let isExitFlollZeroGrossProfit = false
            let orderItem = this.selected[i].ext.items
            let clerkMemo = this.selected[i].clerk_memo

            if (orderItem.length > 0) {
              for (let j = 0; j < orderItem.length; j++) {
                let salesPrice = orderItem[j].sales_price
                let grossProfit = orderItem[j].ext.grossProfit
                if (salesPrice == 0) {
                  isExitZeroSalesPrice = true
                }
                if (isEmpty(clerkMemo) && isNumber(grossProfit) < 0) {
                  isExitFlollZeroGrossProfit = true
                }
              }
            }
            if (isExitFlollZeroGrossProfit) {
              toast.error(orderNo + '订货单存在负毛利商品，请填写文员批注')
              return
            }
          }
        }

        var promise = Promise.resolve()

        for (let i = 0; i < this.selected.length; i++) {
          if (params === '驳回') {
            promise = promise.then(()=>{
              return Promise.resolve().then(()=>{
                return new Promise((resolve, reject) => {
                  store.dispatch('presalesorder/changeStatus', {
                    id: this.selected[i].order_id,
                    status: 5,
                  }).then(res => {
                    if (res.data.code === 0) {
                      toast.success('操作成功')
                    } else {
                      toast.error(res.data.data)
                    }
                    resolve()
                  })
                })
              })
            })
          }
          if (params === '同意'){
            promise = promise.then(()=>{
              return Promise.resolve().then(()=>{
                return new Promise((resolve, reject) => {
                  this.$refs['loadingModal'].show()
                  store.dispatch('presalesorder/checkSuccess', {id: this.selected[i].order_id}).then(res => {
                    if (res.data.code === 0) {
                      toast.success('操作成功')
                    } else {
                      toast.error(res.data.data)
                    }
                    resolve()
                  })
                })
              })
            })
          }
        }
        promise.then(() => {
          refetchData()
          this.$refs['loadingModal'].hide()
        })
      }

    }

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      dataTitle,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      selectedT,

      // UI
    } = presalesorderUseList(
        {
          orderNo: router.route.value.query.orderNo,
        },
    )

    return {
      ...toRefs(state),
      state,
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      dataTitle,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      selectedT,
      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      // UI
      toTime,
      toDate,
      advanced_search,
      fromChildren,
      storeInput,
      changeStatus,
      //selectByStatus,
      //selectAll,
      deliveryNote,
      distribution,
      cleanWarehouseHeadArray,
      setWarehouseHead,
      checkQty,
      checkSalesQty,
      checkBoxQuantity,
      showReceiptAttachments,
      saveAttachments,
      onUploadedExcel,
      isReceiptMethod,
      allOutbound,
      checkSalesPrice,
      toURL,
      deleteItem,
      exportData,
      saveRemark,
      isNumber,
      isEmpty,
      deliveryNotePrintTwo,
      submitInfo,
      updatePreSalesOrder,
      setBbmRefuseReason,
      showBbRejectModal,
      bbConfirm,
      getLoad,
      getTransport,
      changeIsExpenses,
      changeSalesCostType,
      changeIsAdjustPrice,
      showChargeModal,
      allChangeStatus,
    }
  },
  created() {
    const userData = getUserData()
    this.user = userData
    this.psoUserId = getCodeLabel("setting",'pso_user_id').split(',')
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
