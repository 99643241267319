import {ref, watch, computed} from '@vue/composition-api'
import store from '@/store'

// Notification
import {useToast} from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {isEmpty} from "@core/utils/filter";

export default function presalesorderUseList(config) {
    // Use toast
    const toast = useToast()

    const refListTable = ref(null)

    // Table Handlers
    // const tableColumns = [
    //   { key: 'order_no', label: '销售编号', sortable: true },
    //   { key: 'type_id', label: '订单类型', sortable: true },
    //   //{ key: 'source', label: '订单来源   1手机 2PC', sortable: true },
    //   //{ key: 'channel_id', label: '渠道ID', sortable: true },
    //   { key: 'channel_name', label: '渠道', sortable: true },
    //   //{ key: 'channel_address', label: '渠道地址', sortable: true },
    //   //{ key: 'channel_contact_id', label: '渠道联系人ID', sortable: true },
    //   //{ key: 'channel_contact_name', label: '渠道联系人姓名', sortable: true },
    //   //{ key: 'channel_contact_mobile', label: '渠道联系电话', sortable: true },
    //   //{ key: 'store_id', label: '店铺ID', sortable: true },
    //   { key: 'store_name', label: '店铺名称', sortable: true },
    //   { key: 'sales_time', label: '售卖日期', sortable: true },
    //   //{ key: 'sales_id', label: '销售员ID', sortable: true },
    //   { key: 'sales_name', label: '销售员名称', sortable: true },
    //   { key: 'sales_warehouse', label: '销售仓库', sortable: true },
    //   { key: 'subtotal', label: '小计金额', sortable: true },
    //   //{ key: 'item_count', label: '商品件数', sortable: true },
    //   { key: 'memo', label: '备注', sortable: true },
    //   { key: 'status', label: '状态', sortable: true },
    //   //{ key: 'state', label: '状态', sortable: true },
    //   //{ key: 'add_time', label: '创建时间', sortable: true },
    //   //{ key: 'creator', label: '创建人', sortable: true },
    //   //{ key: 'modify_time', label: '修改时间', sortable: true },
    //   //{ key: 'updator', label: '修改人', sortable: true },
    //   { key: 'create_order_id', label: '生成的订单ID', sortable: true },
    //       { key: 'actions', label: '操作' },
    // ]

    const tableColumns = [
        {key: 'order_no', label: 'BBID|订货编号'},
        //{ key: 'type_id', label: '订单类型 1线下 2线上', sortable: true },
        // { key: 'source', label: '订单来源   1手机 2PC', sortable: true },
        // { key: 'channel_id', label: '渠道ID', sortable: true },
        // { key: 'channel_address', label: '渠道地址', sortable: true },
        // { key: 'channel_contact_id', label: '渠道联系人ID', sortable: true },
        // { key: 'channel_contact_name', label: '渠道联系人姓名', sortable: true },
        // { key: 'channel_contact_mobile', label: '渠道联系电话', sortable: true },
        // { key: 'sales_id', label: '销售员ID', sortable: true },
        // { key: 'item_count', label: '商品件数', sortable: true },
        // { key: 'state', label: '状态', sortable: true },

        // { key: 'create_order_id', label: '生成的订单ID', sortable: true },
        // { key: 'delivery_type', label: '送货方式  preorder_delivery_method', sortable: true },
        // { key: 'pay_period', label: '付款周期  preorder_pay_preiod', sortable: true },
        // { key: 'delivery_time', label: '配送日期', sortable: true },
        //{ key: 'clerk_memo', label: '文员批注', sortable: true },
        {key: 'channel_name', label: '终端客户'},
        //{key: 'sales_warehouse', label: '仓库名称'},
        {key: 'item_count', label: '销售数量'},
        {key: 'subtotal', label: '销售金额'},
        {key: 'cost_subtotal', label: '成本金额'},
        {key: 'grossProfit', label: '毛利'},
        {key: 'grossProfitMargin', label: '毛利率'},
        {key: 'sales_time', label: '销售日期'},
        {key: 'sales_name', label: '销售人员'},
        { key: 'delivery_time', label: '配送日期'},
        {key: 'add_time', label: '创建时间/人'},
        //{key: 'creator', label: '创建人'},
        {key: 'modify_time', label: '修改日期/人'},
        //{key: 'updator', label: '修改人'},
        {key: 'memo', label: '订单备注'},
        { key: 'clerk_memo', label: '文员批注'},
        {key: 'print_times', label: '打印次数'},
        {key: 'store_name', label: '团队名称'},
        {key: 'merge_no', label: '配货编号'},
        {key: 'status', label: '订单状态'},
        {key: 'create_order_id', label: '销售单'},
        {key: 'is_checkout', label: '出库'},
        {key: 'is_receipt', label: '回执状态'},
        {key: 'receipt_attachments', label: '附件'},
        { key: 'price_check', label: '售价审核' },
        { key: 'price_check_status', label: '售价审核' },
        { key: 'reviewer', label: '审核人日期/人' },
        {key: 'actions', label: '操作'},
    ]

    const start = ref(1)
    const limit = ref(10)
    const limitOptions = [10, 25, 50, 100,200]
    const listTotals = ref(0)
    const searchKey = ref('')
    const orderBy = ref('modify_time desc ,order_id')
    const isSortDirDesc = ref(true)
    const selectedT = ref(1)
    const dataTitleTotal = ref(0)
    const preOrderStatus0 = ref(0)
    const preOrderStatus1 = ref(0)
    const preOrderStatus2 = ref(0)
    const preOrderStatus3 = ref(0)
    const preOrderStatus4 = ref(0)
    const preOrderStatus5 = ref(0)
    const preOrderStatus6 = ref(0)
    const preOrderStatus7 = ref(0)
    const preOrderStatus8 = ref(0)


    const dataMeta = computed(() => {
        const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
        return {
            from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
            to: limit.value * (start.value - 1) + localItemsCount,
            of: listTotals.value,
        }
    })


    const dataTitle = computed(() => {
        return {
            total: dataTitleTotal.value,
            preOrderStatus0: preOrderStatus0.value === undefined ? 0 : preOrderStatus0.value,
            preOrderStatus1: preOrderStatus1.value === undefined ? 0 : preOrderStatus1.value,
            preOrderStatus2: preOrderStatus2.value === undefined ? 0 : preOrderStatus2.value,
            preOrderStatus3: preOrderStatus3.value === undefined ? 0 : preOrderStatus3.value,
            preOrderStatus4: preOrderStatus4.value === undefined ? 0 : preOrderStatus4.value,
            preOrderStatus5: preOrderStatus5.value === undefined ? 0 : preOrderStatus5.value,
            preOrderStatus6: preOrderStatus6.value === undefined ? 0 : preOrderStatus6.value,
            preOrderStatus7: preOrderStatus7.value === undefined ? 0 : preOrderStatus7.value,
            preOrderStatus8: preOrderStatus8.value === undefined ? 0 : preOrderStatus8.value,
        }
    })


    const refetchData = () => {
        refListTable.value.refresh()
    }

    watch([start, limit, searchKey, selectedT], () => {
        refetchData()
    })

    const searchList = (ctx, callback) => {
        const condition = {...store.getters['presalesorder/getCondition']}
        config.orderNo == null ? null : condition.order_no = config.orderNo
        // console.log("selectedT.value")
        // console.log(selectedT.value)
        let statusClick = null ;
        if(selectedT.value==6){ //待出库
            statusClick = 2
            condition.isCheckout=0
        }
        if(selectedT.value==7) {//待回执
            statusClick = 2
            condition.isCheckout=1
            condition.is_receipt=0
        }
        if(selectedT.value==8) { //已完成
            statusClick = 2
            condition.is_receipt=1
        }
        let statusFal = null;
        if(statusClick==null){
            statusFal =  selectedT != null ? selectedT.value : null
        }else{
            statusFal =  statusClick
        }

        const warehouseArray = []
        if (!isEmpty(condition.warehouseArray)){
            condition.warehouseArray.forEach((value, index, array) => {
                warehouseArray.push(value.value)
            })
        }
        const params = {
            status:statusFal,
            warehouseId: warehouseArray.join(","),
            orderNo: condition.order_no,
            salesId: condition.sales_id,
            salesName: condition.sales_name,
            salesTime: condition.sales_time,
            channelId: condition.channelId,
            salesWarehouse: condition.sales_warehouse,
            isCheckout: condition.isCheckout,
            isCreateSalesOrder: condition.isCreateSalesOrder,
            storeName: condition.storeName,
            storeId: condition.storeid,
            productCondition: condition.productCondition,
            mergeNo: condition.mergeNo,
            addTime: condition.add_time,
            creator: condition.creator,
            isReceipt: condition.is_receipt,
            receiptType: condition.receipt_type,
            isPrint:condition.isPrint,
            start: start.value,
            limit: limit.value,
            order_by: orderBy.value,
            order_desc: isSortDirDesc.value === true ? 'desc' : '',
            bbmId: condition.bbmId,
            bbmItemId: condition.bbmItemId,
            purchaseTeamId: isEmpty(condition.purchaseTeamId)?null:condition.purchaseTeamId.value
        }
        store
            .dispatch('presalesorder/search', params)
            .then(response => {
                const data = response.data.data
                const list = data.ext.list
                listTotals.value = data.ext.totals
                dataTitleTotal.value = data.ext.size
                preOrderStatus0.value = data.ext.preOrderStatus0
                preOrderStatus1.value = data.ext.preOrderStatus1
                preOrderStatus2.value = data.ext.preOrderStatus2
                preOrderStatus3.value = data.ext.preOrderStatus3
                preOrderStatus4.value = data.ext.preOrderStatus4
                preOrderStatus5.value = data.ext.preOrderStatus5
                preOrderStatus6.value = data.ext.preOrderStatus6
                preOrderStatus7.value = data.ext.preOrderStatus7
                preOrderStatus8.value = data.ext.preOrderStatus8
                callback(list)
            })
            .catch((e) => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: '列表获取错误',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                })
            })
    }

    // *===============================================---*
    // *--------- UI ---------------------------------------*
    // *===============================================---*

    return {
        searchList,
        tableColumns,
        limit,
        start,
        listTotals: listTotals,
        dataMeta,
        dataTitle,
        limitOptions,
        searchKey,
        orderBy,
        isSortDirDesc,
        refListTable,
        selectedT,
        refetchData,

        // Extra Filters
    }
}
